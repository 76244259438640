import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react'
import { Blok, InsightsGridSectionStoryblok } from '../types'
import InsightsGridSection from '../../components/organisms/InsightsGridSection'

const StorybloInsightsGridSection: FC<Blok<InsightsGridSectionStoryblok>> = ({ blok }) => {
  const { parentProps } = blok
  return (
    <InsightsGridSection
      {...storyblokEditable(blok)}
      category={parentProps.category}
      pageNumber={parentProps.pageNumber}
      pathname={parentProps.pathname}
    />
  )
}

export default StorybloInsightsGridSection
