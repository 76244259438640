import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, TableStoryblok } from '../types'
import Table from '../../components/atoms/Table'

const StoryblokTable: FC<Blok<TableStoryblok>> = ({ blok: { content, ...props } }) => {
  if (!content) return null
  return <Table content={content} {...storyblokEditable(props)} />
}

export default StoryblokTable
