import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react'
import Rating from '../../components/molecules/Rating'
import { Blok, RatingStoryblok } from '../types'

const StoryblokRating: FC<Blok<RatingStoryblok>> = ({ blok: { rating, totalStars, ...blok } }) => (
  <Rating {...storyblokEditable(blok)} totalStars={Number(totalStars)} rating={Number(rating)} />
)

export default StoryblokRating
