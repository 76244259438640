import { FC } from 'react'
import { Blok, RequestChecklistFormStoryblok } from '../types'
import RequestChecklistForm from '../../components/molecules/RequestChecklistForm'
import { extractStoryblokLink } from '../../utils/extractStoryblokLink'

const StoryblokRequestChecklistForm: FC<Blok<RequestChecklistFormStoryblok>> = ({
  blok: { linkToPrivacyPolicy, linkToThankYouPage }
}) => (
  <RequestChecklistForm
    linkToPrivacyPolicy={extractStoryblokLink(linkToPrivacyPolicy)}
    linkToThankYouPage={extractStoryblokLink(linkToThankYouPage)}
  />
)

export default StoryblokRequestChecklistForm
