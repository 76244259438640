import React, { useId } from 'react'
import {
  buttonVariants,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  cn
} from '@dbbs/tailwind-components'
import { ArrowLeft, ArrowRight } from 'iconoir-react'
import type { VariantProps } from 'class-variance-authority'
import { BlogSectionProps } from './types'
import BlogCarouselCard from '../../molecules/BlogCarouselCard'

const BlogSection = ({
  titleSection,
  posts,
  isLightBackground = false,
  prioritizeFirstTwoImages = false
}: BlogSectionProps) => {
  const contentContainerId = useId()

  const carouselButtonVariant: VariantProps<typeof buttonVariants>['variant'] = isLightBackground
    ? 'secondary'
    : 'secondaryDarkBG'

  return (
    <Carousel className="flex flex-col gap-4 md:gap-12" opts={{ align: 'start' }}>
      <div className="flex px-4 lg:px-16 items-center justify-between">
        {titleSection}

        <div className={cn('flex gap-4', { 'md:hidden': posts.length < 3 })}>
          <CarouselPrevious
            variant={carouselButtonVariant}
            size="default"
            className="static -right-0 -top-0 -translate-y-0"
            iconPrevious={ArrowLeft}
          />

          <CarouselNext
            variant={carouselButtonVariant}
            size="default"
            className="static -left-0 -top-0 -translate-y-0"
            iconNext={ArrowRight}
          />
        </div>
      </div>
      <CarouselContent className="-ml-0 gap-7 mx-4 lg:mx-16" id={contentContainerId}>
        {posts.map((item, index) => (
          <CarouselItem
            key={`carousel-item-${item.title}-${index}`}
            className="pl-0 basis-[70%] sm:basis-[55%] md:basis-[35%]"
          >
            <BlogCarouselCard
              link={item.linkToPost}
              dateOfPublication={item.first_published_at}
              title={item.title}
              priority={prioritizeFirstTwoImages ? index < 2 : false}
              srcImg={item.previewImage}
              isLightBackground={isLightBackground}
              intersectionLazy
              contentContainerId={contentContainerId}
            />
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  )
}

export default BlogSection
