'use client'

import { useCallback, useEffect, useState } from 'react'
import Script from 'next/script'
import { AnalyticsProps } from './types'

const userInteractionEvents = ['mousemove', 'touchstart', 'keydown']

const Analytics = ({ isDraftMode, environment }: AnalyticsProps) => {
  const [isUserInteractionStarted, setIsUserInteractionStarted] = useState(false)

  const listener = useCallback(() => setIsUserInteractionStarted(true), [])

  const isProduction = environment === 'production'

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    if (isUserInteractionStarted) return
    userInteractionEvents.forEach((event) => document.addEventListener(event, listener, { once: true, passive: true }))

    const id = setTimeout(() => {
      listener()
      userInteractionEvents.forEach((event) => document.removeEventListener(event, listener))
    }, 5000)

    return () => {
      clearTimeout(id)
      userInteractionEvents.forEach((event) => document.removeEventListener(event, listener))
    }
  }, [isUserInteractionStarted, listener])

  if (!isProduction || isDraftMode || !isUserInteractionStarted) return null

  return (
    <>
      <Script async src="https://www.googletagmanager.com/gtm.js?id=G-BPRBC0Z9V7" />
      <Script
        id="google-tag-manager-no-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag("js", new Date());
              gtag("config", "G-BPRBC0Z9V7");
            `
        }}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TFZX7JHC');
            `
        }}
      />
      <Script
        id="cookieyes"
        strategy="afterInteractive"
        src="https://cdn-cookieyes.com/client_data/2897ed574b26a8c8ab309631/script.js"
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1736329860499915');
                fbq('track', 'PageView');
              `
        }}
      />
      <Script async src="https://analytics.ahrefs.com/analytics.js" data-key="t/3cfq//68CL9VzBRvp5cg" />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.va = window.va || function () { (window.vaq = window.vaq || []).push(arguments); };
          `
        }}
      />
      <Script async src="/_vercel/insights/script.js" />
    </>
  )
}

export default Analytics
