import { cn } from '@dbbs/tailwind-components'
import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { resolveBreakpoints } from '../../utils/resolveBreakpoints'
import { Blok, FlexItemStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokFlexItem: FC<Blok<FlexItemStoryblok>> = ({ blok }) => {
  const { content } = blok

  return (
    <div
      className={cn(
        resolveBreakpoints(blok, 'order', 'order-{value}'),
        resolveBreakpoints(blok, 'flexGrow', '{value}'),
        resolveBreakpoints(blok, 'flexShrink', '{value}'),
        resolveBreakpoints(blok, 'flexBasis', 'basis-{value}'),
        resolveBreakpoints(blok, 'alignSelf', 'self-{value}')
      )}
      {...storyblokEditable(blok)}
    >
      <StoryblokBlockMapper blocks={content} />
    </div>
  )
}
export default StoryblokFlexItem
