import { FC } from 'react'
import { ISbStoryData } from 'storyblok'
import { Blok, CaseStudyCarouselStoryblok, CaseStudyPageStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import CaseStudyCarousel from '../../components/organisms/CaseStudyCarousel'

const StoryblokCaseStudyCarousel: FC<Blok<CaseStudyCarouselStoryblok>> = ({
  blok: {
    title,
    caseStudies,
    dragFree,
    dragScrolling,
    loop,
    fadeEffect,
    autoScroll,
    playOnInit,
    stopOnMouseEnter,
    stopOnInteraction,
    isLightBackground
  }
}) => {
  const filteredCaseStudies = (caseStudies || [])
    .filter(
      (item): item is ISbStoryData<CaseStudyPageStoryblok> =>
        typeof item === 'object' && item !== null && 'content' in item && !!item.content.slide?.length
    )
    .map((item) => (
      <StoryblokBlockMapper
        key={item.uuid}
        blocks={item.content.slide}
        parentProps={{ isLightBackground: !!isLightBackground }}
      />
    ))

  return (
    <CaseStudyCarousel
      title={<StoryblokBlockMapper blocks={title} />}
      slides={filteredCaseStudies}
      playOnInit={playOnInit}
      stopOnInteraction={stopOnInteraction}
      stopOnMouseEnter={stopOnMouseEnter}
      loop={loop}
      dragFree={dragFree}
      autoScroll={autoScroll}
      fadeEffect={fadeEffect}
      dragScrolling={dragScrolling}
      controlButtonVariant={isLightBackground ? 'secondary' : 'secondaryDarkBG'}
    />
  )
}

export default StoryblokCaseStudyCarousel
