interface JsonLdProps {
  schemas: unknown[]
}

const JsonLd = ({ schemas }: JsonLdProps) =>
  schemas.map((schema, index) => (
    <script
      key={`json-ld-${index}`}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  ))

export default JsonLd
