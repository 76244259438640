const stage = process.env.STAGE

export default function getDomainName() {
  switch (stage) {
    case 'development':
      return 'https://development.dbbsoftware.com'
    case 'staging':
      return 'https://staging.dbbsoftware.com'
    case 'production':
      return 'https://dbbsoftware.com'
    default:
      return 'https://dbbsoftware.com'
  }
}
