import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, StaffCarouselStoryblok } from '../types'
import StaffCarousel from '../../components/organisms/StaffCarousel'

const StoryblokStaffCarousel: FC<Blok<StaffCarouselStoryblok>> = ({ blok: { images, ...blok } }) => (
  <StaffCarousel {...storyblokEditable(blok)} images={images} />
)

export default StoryblokStaffCarousel
