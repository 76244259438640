import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, ContainerStoryblok } from '../types'
import Container from '../../components/atoms/Container'
import { Dimensions } from '../../types/designSystem'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokContainer: FC<Blok<ContainerStoryblok>> = ({ blok }) => {
  const { content, paddingLeft, paddingRight, parentProps } = blok

  return (
    <Container
      {...storyblokEditable(blok)}
      paddingRight={paddingRight as Dimensions}
      paddingLeft={paddingLeft as Dimensions}
    >
      <StoryblokBlockMapper blocks={content} parentProps={parentProps} />
    </Container>
  )
}

export default StoryblokContainer
