import { BlockTypes, MarkTypes } from '@storyblok/richtext'
import { RichtextStoryblok } from '../storyblok/types'

/**
 * Recursively removes marks of type `TEXT_STYLE` from an array of `RichtextStoryblok` items,
 * while preserving all other properties and skipping items of type `COMPONENT`.
 *
 * @param {RichtextStoryblok[]} content - The array of rich text content to process.
 * @returns {RichtextStoryblok[]} A new array with `TEXT_STYLE` marks removed, except for items of type `COMPONENT`.
 */
export const removeRichTextStyleMarks = (content: RichtextStoryblok[]): RichtextStoryblok[] =>
  content.map((item) => {
    if (item.type === BlockTypes.COMPONENT) {
      return item
    }

    return {
      ...item,
      marks: item.marks
        ? item.marks.filter((mark: RichtextStoryblok) => mark.type !== MarkTypes.TEXT_STYLE)
        : undefined,
      content: item.content ? removeRichTextStyleMarks(item.content) : undefined
    }
  })
