import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, CaseStudyFiltersAndListStoryblok } from '../types'

import CaseStudyFiltersAndList from '../../components/organisms/CaseStudyFiltersAndList'

const StoryblokCaseStudyFiltersAndList: FC<Blok<CaseStudyFiltersAndListStoryblok>> = ({ blok }) => {
  const { parentProps } = blok

  return (
    <CaseStudyFiltersAndList
      {...storyblokEditable(blok)}
      filterServiceSlug={parentProps.filterService}
      filterIndustriesSlug={parentProps.filterIndustry}
    />
  )
}

export default StoryblokCaseStudyFiltersAndList
