import { FC } from 'react'
import { Blok, CompanyHistoryCarouselStoryblok } from '../types'
import CompanyHistoryCarousel from '../../components/organisms/CompanyHistoryCarousel'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokCompanyHistoryCarousel: FC<Blok<CompanyHistoryCarouselStoryblok>> = ({ blok }) => (
  <CompanyHistoryCarousel title={<StoryblokBlockMapper blocks={blok.title} />} historyCards={blok.listHistoryCards} />
)

export default StoryblokCompanyHistoryCarousel
