import { FC } from 'react'
import Image from 'next/image'
import { storyblokEditable } from '@storyblok/react/rsc'
import { cn } from '@dbbs/tailwind-components'
import { Blok, ImageStoryblok } from '../types'

const StoryblokImage: FC<Blok<ImageStoryblok>> = ({
  blok: { source, width, height, objectFit, priority, unoptimized, fullHeight, fullWidth, ...blok }
}) =>
  source?.filename && (
    <Image
      {...storyblokEditable(blok)}
      src={source.filename}
      width={Number(width || source?.width || 120)}
      height={Number(height || source?.height || 120)}
      alt={source?.alt ?? ''}
      priority={priority}
      unoptimized={unoptimized}
      className={cn(objectFit && `object-${objectFit}`, { 'h-full': fullHeight, 'w-full': fullWidth })}
    />
  )

export default StoryblokImage
