import { FC } from 'react'
import { Blok, CaseStudySlideStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import CaseStudySlide from '../../components/molecules/CaseStudySlide'

const StoryblokCaseStudySlide: FC<Blok<CaseStudySlideStoryblok>> = ({ blok }) => (
  <CaseStudySlide
    challengeContent={<StoryblokBlockMapper blocks={blok.challengeContent} />}
    imageCase={blok.image}
    result={<StoryblokBlockMapper blocks={blok.result} />}
    solutionContent={<StoryblokBlockMapper blocks={blok.solutionContent} />}
    title={<StoryblokBlockMapper blocks={blok.title} />}
    watchDemoUrl={blok.watchDemo?.url}
    learnMoreUrl={blok.learnMore?.url}
    isLightBackground={blok?.parentProps?.isLightBackground || false}
  />
)

export default StoryblokCaseStudySlide
