import { FC } from 'react'
import { cn } from '@dbbs/tailwind-components'
import { Blok, VideoStoryblok } from '../types'

const StoryblokVideo: FC<Blok<VideoStoryblok>> = ({
  blok: { poster, video, fullWidth, orientation = 'horizontal', ...props }
}) => {
  if (!poster.filename || !video.filename) return null

  return (
    <video
      poster={poster.filename}
      className={cn('object-cover', {
        'w-full': fullWidth,
        'aspect-[9/16]': orientation === 'vertical',
        'aspect-[16/9]': orientation === 'horizontal'
      })}
      {...props}
    >
      <source src={video.filename} type="video/mp4" />
    </video>
  )
}

export default StoryblokVideo
