import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react'
import { Blok, CaseStudyPageStoryblok, CaseStudyPanelStoryblok } from '../types'
import CaseStudyPanel from '../../components/organisms/CaseStudyPanel'
import { fetchCaseStudyByUUIDs } from '../../components/organisms/CaseStudyPanel/action'
import { CaseStudyResponseObject } from '../../components/organisms/CaseStudyPanel/types'

const StoryblokCaseStudyPanel: FC<Blok<CaseStudyPanelStoryblok>> = async ({ blok: { caseStudies, ...blok } }) => {
  try {
    const data = await fetchCaseStudyByUUIDs({ uuid: caseStudies, resolveRelations: ['caseStudyPage.review'] })
    const cardData = data.data.stories.map((item: CaseStudyResponseObject<CaseStudyPageStoryblok>) => {
      const [review] = item.content.review ?? []
      const feedbackText = review && typeof review !== 'string' ? review?.content.review : ''
      const authorText =
        review && typeof review !== 'string' ? `${review.content.name}, ${review.content.position}` : ''

      return {
        linkToCase: item.full_slug,
        title: item.content.panelCard[0].title,
        description: item.content.panelCard[0].description,
        image: item.content.panelCard[0].image,
        bgColor: item.content.color.color,
        feedback: feedbackText,
        author: authorText
      }
    })

    return <CaseStudyPanel cards={cardData} {...storyblokEditable(blok)} />
  } catch (error) {
    console.error(error)
    return null
  }
}

export default StoryblokCaseStudyPanel
