import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, HeroStoryblok } from '../types'
import Hero from '../../components/organisms/Hero'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import { DEFAULT_HERO_BACKGROUND_IMAGE_PATH, DEFAULT_HERO_BACKGROUND_VIDEO_PATH } from '../../constants/background'

const StoryblokHero: FC<Blok<HeroStoryblok>> = ({
  blok: { title, badge, buttons, description, trustedByContent, backgroundImage, backgroundVideo, ...props }
}) => (
  <Hero
    title={<StoryblokBlockMapper blocks={title} />}
    description={<StoryblokBlockMapper blocks={description} />}
    badge={<StoryblokBlockMapper blocks={badge} />}
    buttons={<StoryblokBlockMapper blocks={buttons} />}
    trustedBy={<StoryblokBlockMapper blocks={trustedByContent} />}
    backgroundImageUrl={backgroundImage?.filename ? backgroundImage?.filename : DEFAULT_HERO_BACKGROUND_IMAGE_PATH}
    backgroundImageVideoUrl={backgroundVideo?.filename ? backgroundVideo?.filename : DEFAULT_HERO_BACKGROUND_VIDEO_PATH}
    {...storyblokEditable(props)}
  />
)

export default StoryblokHero
