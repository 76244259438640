import { storyblokEditable } from '@storyblok/react/rsc'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, cn } from '@dbbs/tailwind-components'
import { FC } from 'react'
import { Minus, Plus } from 'iconoir-react'
import { AccordionItemStoryblok, AccordionStoryblok, Blok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import { generateJsonLdFAQPage } from '../../utils/generateJsonLdSchemas'
import JsonLd from '../../components/atoms/JsonLd'

const extractTextRecursively = (node: AccordionItemStoryblok['content'][number]): string => {
  if (typeof node === 'string') return node
  if ('text' in node && node.text) return extractTextRecursively(node.text)
  if (Array.isArray(node.content)) {
    return node.content.map(extractTextRecursively).filter(Boolean).join(' ')
  }

  return ''
}

const extractAccordionAnswers = (accordionItemContent: AccordionItemStoryblok['content']): string =>
  accordionItemContent.map(extractTextRecursively).filter(Boolean).join('\n')

const StoryblokAccordion: FC<Blok<AccordionStoryblok>> = ({
  blok: { content, type, collapsible, showDivider, hasJsonLdSchema, ...blok }
}) => {
  const renderContent = () => (
    <div className="flex flex-col gap-4 md:gap-6">
      {content.map(({ title, content: accordionItemContent }, index) => (
        <AccordionItem
          value={title[0].text}
          key={`accordion-${title[0].text}-${index}`}
          className={cn({
            'border-b pb-sm data-[state=open]:pb-xx-sm border-line-grey': showDivider && index !== content.length - 1,
            'border-none': !showDivider || index === content.length - 1
          })}
        >
          <AccordionTrigger
            openIcon={Plus}
            closeIcon={Minus}
            className="[&[data-state=open]>:first-child]:text-primary p-0"
          >
            <StoryblokBlockMapper blocks={title} />
          </AccordionTrigger>
          <AccordionContent>
            <StoryblokBlockMapper blocks={accordionItemContent} />
          </AccordionContent>
        </AccordionItem>
      ))}
    </div>
  )

  return (
    <>
      {type === 'single' ? (
        <Accordion
          type="single"
          collapsible={collapsible}
          defaultValue={content[0].title[0].text}
          {...storyblokEditable(blok)}
        >
          {renderContent()}
        </Accordion>
      ) : (
        <Accordion type="multiple" {...storyblokEditable(blok)}>
          {renderContent()}
        </Accordion>
      )}
      {hasJsonLdSchema && (
        <JsonLd
          schemas={[
            generateJsonLdFAQPage({
              faqs: content.map(({ title, content: accordionItemContent }) => ({
                question: title[0].text,
                answer: extractAccordionAnswers(accordionItemContent)
              }))
            })
          ]}
        />
      )}
    </>
  )
}

export default StoryblokAccordion
