import React, { PropsWithChildren } from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '@dbbs/tailwind-components'
import { Dimensions } from '../../../types/designSystem'

const paddingLeftDimensions = {
  sm: 'pl-1.5',
  md: 'pl-8',
  lg: 'pl-16',
  none: 'pl-0'
}

const paddingRightDimensions = {
  sm: 'pr-1.5',
  md: 'pr-8',
  lg: 'pr-16',
  none: 'pr-0'
}

interface ContainerProps extends PropsWithChildren {
  paddingLeft: Dimensions
  paddingRight: Dimensions
}

const container = cva(['container'], {
  variants: {
    paddingLeft: paddingLeftDimensions,
    paddingRight: paddingRightDimensions
  },
  defaultVariants: {
    paddingLeft: 'md',
    paddingRight: 'md'
  }
})

const Container = ({ children, paddingLeft, paddingRight, ...props }: ContainerProps) => (
  <div className={cn(container({ paddingLeft, paddingRight }))} {...props}>
    {children}
  </div>
)

export default Container
