import { fetchAllIndustryCategories, fetchAllServiceCategories, fetchCaseStudies } from './actions'
import { CaseStudiesResponse, CaseStudyFiltersAndListParams, CategoryGroup, CategoryResponseCaseStudy } from './types'
import CaseStudyFilterAccordion from '../../molecules/CaseStudyFilterAccrodion'
import CaseStudyFilterDropdownMenu from '../../molecules/CaseStudyFilterDropdownMenu'
import CaseStudyList from '../../molecules/CaseStudyList'
import { generateJsonLdCollectionPage, generateJsonLdCreativeWork } from '../../../utils/generateJsonLdSchemas'
import JsonLd from '../../atoms/JsonLd'

const buildCategoryGroup = (
  title: string,
  data: CategoryResponseCaseStudy,
  pathname: string,
  queryKey: 'industry' | 'service',
  currentSlug?: string,
  otherSlug?: string,
  otherQueryKey?: 'industry' | 'service'
): CategoryGroup => ({
  title,
  categories: data.stories.map((item) => ({
    ...item,
    link: {
      pathname,
      query: {
        [queryKey]: item.slug,
        ...(otherSlug && { [otherQueryKey!]: otherSlug })
      }
    }
  })),
  selectedCategory: data.stories.find((item) => item.slug === currentSlug),
  linkGroup: {
    pathname,
    query: {
      ...(otherSlug && { [otherQueryKey!]: otherSlug })
    }
  }
})

const CaseStudyFiltersAndList = async ({ filterIndustriesSlug, filterServiceSlug }: CaseStudyFiltersAndListParams) => {
  try {
    const [industriesRes, servicesRes] = await Promise.all([fetchAllIndustryCategories(), fetchAllServiceCategories()])
    const { data: industryCategories } = industriesRes as { data: CategoryResponseCaseStudy }
    const { data: serviceCategories } = servicesRes as { data: CategoryResponseCaseStudy }
    const pathname = '/case-studies'

    const industryGroup = buildCategoryGroup(
      'Industries',
      industryCategories,
      pathname,
      'industry',
      filterIndustriesSlug,
      filterServiceSlug,
      'service'
    )

    const serviceGroup = buildCategoryGroup(
      'Services',
      serviceCategories,
      pathname,
      'service',
      filterServiceSlug,
      filterIndustriesSlug,
      'industry'
    )

    const categoryGroups = [industryGroup, serviceGroup]
    const { data: caseStudies } = (await fetchCaseStudies({
      industryId: industryGroup.selectedCategory?.uuid,
      serviceId: serviceGroup.selectedCategory?.uuid
    })) as { data: CaseStudiesResponse }

    return (
      <div className="flex gap-x-md gap-y-md-deluxe flex-col md:flex-row md:min-h-[800px]">
        <div className="hidden md:block basis-1/3 md:py-lg md:pl-lg lg:p-lg">
          <CaseStudyFilterAccordion categoryGroups={categoryGroups} />
        </div>
        <div className="w-full flex flex-col gap-x-sm md:hidden px-none sm:px-lg">
          {categoryGroups.map((group, indexGroup) => (
            <CaseStudyFilterDropdownMenu group={group} key={`dm-group-${group.title}-${indexGroup}`} />
          ))}
        </div>
        <div className={'basis-full md:basis-2/3'}>
          <CaseStudyList
            initCaseStudiesData={caseStudies}
            industryId={industryGroup.selectedCategory?.uuid}
            serviceId={serviceGroup.selectedCategory?.uuid}
          />
        </div>
        <JsonLd
          schemas={[
            generateJsonLdCollectionPage({
              name: 'Case Studies',
              description:
                'Explore case studies of successful software development projects delivered by DBB Software.',
              url: '/case-studies',
              hasPart: caseStudies.stories.map((story) =>
                generateJsonLdCreativeWork({
                  url: story.full_slug,
                  name: story.name,
                  description: story.content.meta[0].description,
                  datePublished: story.published_at,
                  dateModified: story.updated_at,
                  image: story.content.meta[0]?.ogImage?.[0]?.filename,
                  applicationCategory: story.content.applicationCategory,
                  operatingSystem: story.content.operatingSystem
                })
              )
            })
          ]}
        />{' '}
      </div>
    )
  } catch (error) {
    console.error(error)
    return null
  }
}

export default CaseStudyFiltersAndList
