import { storyblokInit as sbInit } from '@storyblok/js'
import { apiPlugin, setComponents } from '@storyblok/react/rsc'
import { StoryblokClient } from '@storyblok/react'
import components from '../components'

const previewApiToken = process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_API_TOKEN
const publicApiToken = process.env.NEXT_PUBLIC_STORYBLOK_PUBLIC_API_TOKEN

const getStoryblokApi = (isDraftMode: boolean = false): StoryblokClient => {
  const { storyblokApi } = sbInit({
    accessToken: isDraftMode ? previewApiToken : publicApiToken,
    use: [apiPlugin]
  })

  if (!storyblokApi) {
    throw new Error('Storyblok API not initialized')
  }

  setComponents(components)

  return storyblokApi
}

export default getStoryblokApi
