import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, NavigationGroupStoryblok } from '../types'
import NavigationGroup from '../../components/molecules/NavigationGroup'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokNavigationGroup: FC<Blok<NavigationGroupStoryblok>> = ({ blok: { title, links, ...blok } }) => (
  <NavigationGroup {...storyblokEditable(blok)} title={title}>
    <StoryblokBlockMapper blocks={links} />
  </NavigationGroup>
)

export default StoryblokNavigationGroup
