import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react'
import { Blok, SpaceStoryblok } from '../types'
import Space from '../../components/atoms/Space'

const StoryblokSpace: FC<Blok<SpaceStoryblok>> = ({ blok: { size, ...blok } }) => (
  <Space {...storyblokEditable(blok)} size={size || 'medium'} />
)

export default StoryblokSpace
