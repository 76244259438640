const TEMPLATE_REGEX = /\{[^}]+\}/g
export const resolveBreakpoints = (
  values: Record<string, string | undefined>,
  property: string,
  template: string
): string => {
  const { [`${property}`]: base, [`${property}Md`]: md, [`${property}Lg`]: lg, [`${property}Xl`]: xl } = values

  const styles = [
    xl ? `xl:${template.replace(TEMPLATE_REGEX, xl)}` : '',
    lg ? `lg:${template.replace(TEMPLATE_REGEX, lg)}` : '',
    md ? `md:${template.replace(TEMPLATE_REGEX, md)}` : '',
    base ? `${template.replace(TEMPLATE_REGEX, base)}` : ''
  ]

  return styles.filter(Boolean).join(' ')
}
