import publicStoryblokApi from '../../../storyblok/common/publicStoryblokApi'

interface FetchServiceByUUID {
  uuid: string[]
  resolveRelations?: string[]
}

export const fetchCaseStudyByUUIDs = async ({ uuid, resolveRelations = [] }: FetchServiceByUUID) => {
  try {
    const storyblokApi = publicStoryblokApi()
    const response = await storyblokApi.get(
      'cdn/stories',
      {
        starts_with: 'case-studies/',
        content_type: 'caseStudyPage',
        version: 'published',
        by_uuids: uuid.join(','),
        ...(resolveRelations.length && { resolve_relations: resolveRelations })
      },
      { cache: 'no-cache' }
    )

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
