import Image from 'next/image'
import Typography from '../../atoms/Typography'
import { TechCardProps } from './types'

const TechCard = ({ techImgUrl, techName }: TechCardProps) => (
  <div className="transition-all duration-300 group cursor-pointer flex flex-col gap-2 items-center justify-between grayscale hover:grayscale-0 lg:py-4">
    <Image src={techImgUrl} alt={techName} width={56} height={56} className="h-14 w-14 object-contain" />
    <Typography variant="link" className="self-bottom invisible hidden group-hover:visible md:block text-center">
      {techName}
    </Typography>
  </div>
)

export default TechCard
