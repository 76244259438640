import Image from 'next/image'
import Space from '../../atoms/Space'
import Typography from '../../atoms/Typography'
import { ProjectSummaryItemProps } from './types'

const ProjectSummaryItem = ({ title, value, image }: ProjectSummaryItemProps) => (
  <section>
    <Typography variant="body-large" weight="medium">
      {title}
    </Typography>
    <Space size="xxSmall" />
    {image ? (
      <div className="flex gap-3">
        <Image src={image?.filename ?? ''} alt={image?.alt ?? ''} height={20} width={30} />
        {value && (
          <Typography variant="h5" weight="semibold">
            {value}
          </Typography>
        )}
      </div>
    ) : (
      <Typography variant="h5" weight="semibold">
        {value}
      </Typography>
    )}
  </section>
)

export default ProjectSummaryItem
