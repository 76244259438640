import { TableProps } from './types'
import Typography from '../Typography'

const Table = ({ content }: TableProps) => (
  <div className="flex flex-col">
    <div className="overflow-x-auto">
      <div className="inline-block min-w-full">
        <div className="overflow-hidden">
          <table className="overflow-x-auto min-w-full text-left">
            <thead className="border-y border-y-line-grey">
              <tr>
                {content.thead.map((th) => (
                  <th className="py-6 pr-md" key={th._uid}>
                    <Typography variant="h5" className="overflow-hidden max-w-48 text-ellipsis whitespace-nowrap">
                      {th.value}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {content.tbody.map((tr) => (
                <tr key={tr._uid} className="border-b border-b-line-grey">
                  {tr.body.map((td) => (
                    <td key={td._uid} className="py-6 pr-md align-top min-w-48">
                      <Typography variant="body-large">{td.value}</Typography>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
)

export default Table
