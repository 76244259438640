import Link from 'next/link'
import { buttonVariants, cn } from '@dbbs/tailwind-components'
import { DOTS, usePagination } from '../../../hooks/usePagination'
import { PaginationProps } from './types'

const generatePageLink = ({
  pageNum,
  authorSlug,
  pathname
}: {
  pageNum: number
  authorSlug?: string
  pathname: string
}) => {
  let pathnameWithPage: string

  if (authorSlug) {
    if (/\/page\/\d+/.test(pathname)) {
      pathnameWithPage = pathname.replace(/\/page\/\d+/, `/page/${pageNum}`)
    } else {
      pathnameWithPage = `${pathname}/page/${pageNum}`
    }
  } else if (/\/page\/\d+/.test(pathname)) {
    pathnameWithPage = pathname.replace(/\/page\/\d+/, `/page/${pageNum}`)
  } else {
    pathnameWithPage = `${pathname}/page/${pageNum}`
  }

  return pathnameWithPage
}

const Pagination = ({ total, perPage, currentPage, authorSlug, pathname }: PaginationProps) => {
  const paginationRange = usePagination({
    totalCount: total,
    perPage,
    currentPage,
    siblingCount: 2
  })

  return (
    <div className="flex gap-7 items-center">
      {paginationRange &&
        paginationRange.map((pageNum, index) => {
          if (pageNum === DOTS) {
            return (
              <div key={index} className="text-secondary-grey">
                {DOTS}
              </div>
            )
          }

          return (
            <Link
              rel="canonical"
              key={`index-${index}-${pageNum}`}
              scroll={false}
              href={generatePageLink({
                pageNum: Number(pageNum),
                authorSlug,
                pathname
              })}
              className={buttonVariants({
                variant: 'secondary',
                size: 'link',
                className: cn('px-4 py-3', {
                  'bg-primary text-white': currentPage ? pageNum === currentPage : index === 0
                })
              })}
            >
              {pageNum}
            </Link>
          )
        })}
    </div>
  )
}

export default Pagination
