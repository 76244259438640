import BlogCarouselCard from '../../molecules/BlogCarouselCard'
import Pagination from '../../molecules/Pagination'
import CategoryPanel from '../../molecules/CategoryPanel'
import Space from '../../atoms/Space'
import SubscribeCard from '../../molecules/SubscribeCard'
import { InsightsGridSectionProps, MappedCategory, StoryblokArticle, StoryblokCategory } from './types'
import Typography from '../../atoms/Typography'
import { fetchAllCategoriesInInsightsGridSection, fetchInsightsInInsightsGridSection } from './api'
import { COUNT_INSIGHTS_PER_PAGE } from '../../../constants/common'

const InsightsGridSection = async ({
  title,
  pageNumber,
  category,
  authorId,
  authorSlug,
  pathname
}: InsightsGridSectionProps) => {
  try {
    const validPageNumber = Number(pageNumber) || 1
    const validCategory = category || ''
    const validPathname = pathname || '/'
    const { data: categoriesResponse } = await fetchAllCategoriesInInsightsGridSection()
    const categories = categoriesResponse.stories.map(async (categoryItem: StoryblokCategory) => {
      const { data: articleDataByCategory } = await fetchInsightsInInsightsGridSection({
        perPage: 1,
        categoryId: categoryItem.uuid,
        authorId
      })

      return {
        name: categoryItem.content.title,
        slug: categoryItem.slug,
        uuid: categoryItem.uuid,
        hasInsights: !!articleDataByCategory.stories.length
      }
    }) as MappedCategory[]

    const resolvedCategories = await Promise.all(categories)

    const categoriesWithInsights = resolvedCategories.filter((categoryItem) => categoryItem.hasInsights)
    if (!categoriesWithInsights.length) {
      return null
    }

    const selectedUUIDCategory = categoriesWithInsights.find(
      (categoryItem) => categoryItem.slug === validCategory
    )?.uuid

    if (validCategory && !categoriesWithInsights.some((categoryItem) => categoryItem.slug === validCategory)) {
      return null
    }

    const { data, headers: headersSbResponse } = await fetchInsightsInInsightsGridSection({
      authorId,
      numberPage: validPageNumber,
      perPage: COUNT_INSIGHTS_PER_PAGE,
      categoryId: selectedUUIDCategory
    })

    const correctHeaders = headersSbResponse as unknown as Record<string, string>

    const totalInsights = correctHeaders.total ? parseInt(correctHeaders.total, 10) : 0

    const insightsGridItems = data.stories.map((story: StoryblokArticle) => (
      <BlogCarouselCard
        key={story.slug}
        dateOfPublication={story.first_published_at}
        link={story.full_slug}
        title={story.content.title}
        srcImg={story.content.previewImage.filename}
        isLightBackground={true}
      />
    ))

    if (!authorId) {
      insightsGridItems.splice(
        Math.floor(Math.random() * insightsGridItems.length),
        0,
        <SubscribeCard key="subscribe-card" />
      )
    }

    return (
      <div className="flex flex-col items-center">
        {title && (
          <div className="flex flex-col px-3">
            <Typography variant="h2" className="uppercase text-center">
              {title}
            </Typography>
            <Space size="mediumDelux" />
          </div>
        )}
        <CategoryPanel
          categories={categoriesWithInsights}
          selectedCategorySlug={validCategory}
          authorSlug={authorSlug}
        />
        <Space size="large" />
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-x-8 lg:grid-y-16">
          {insightsGridItems}
        </div>
        <Space size="xLarge" />
        {totalInsights > COUNT_INSIGHTS_PER_PAGE && (
          <div>
            {
              <Pagination
                total={totalInsights}
                perPage={COUNT_INSIGHTS_PER_PAGE}
                currentPage={validPageNumber}
                authorSlug={authorSlug}
                pathname={validPathname}
              />
            }
          </div>
        )}
      </div>
    )
  } catch (error) {
    console.error(error)
    return null
  }
}

export default InsightsGridSection
