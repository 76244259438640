import { CarouselContent, CarouselItem, Carousel } from '@dbbs/tailwind-components'
import Image from 'next/image'

import StaffCarouselProps from './types'

const StaffCarousel = ({ images }: StaffCarouselProps) => {
  const getExtendedImages = () => {
    const minRequiredSlides = 6
    if (images.length === 0) {
      return []
    }

    if (images.length >= minRequiredSlides) {
      return images
    }

    const multiplier = Math.ceil(minRequiredSlides / images.length)

    return Array.from({ length: multiplier }).flatMap(() => images)
  }

  const extendedImages = getExtendedImages()

  return (
    <Carousel
      autoScroll
      autoScrollOptions={{ playOnInit: true, stopOnMouseEnter: true, speed: 1, stopOnInteraction: false }}
      autoplay={false}
      autoplayOptions={{ delay: 3000, playOnInit: false }}
      opts={{
        align: 'start',
        dragFree: true,
        loop: true,
        watchDrag: true
      }}
    >
      <CarouselContent>
        {extendedImages.map(({ filename, alt, id }, index) => (
          <CarouselItem key={`${id}-${index}`} className="basis-full sm:basis-[60%] lg:basis-[30%] pl-md">
            <Image
              src={filename ?? ''}
              alt={alt ?? ''}
              width={360}
              height={250}
              className="aspect-[1.4/1] object-cover w-full h-[250px]"
            />
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  )
}

export default StaffCarousel
