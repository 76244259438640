import { FC } from 'react'
import { BlogCarouselSectionStoryblok, Blok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import BlogSection from '../../components/organisms/BlogSection'
import { PostItem } from '../../components/organisms/BlogSection/types'

const StoryblokBlogCarouselSection: FC<Blok<BlogCarouselSectionStoryblok>> = ({
  blok: { blogs, title, isLightBackground, prioritizeFirstTwoImages }
}) => {
  const blogItems = (blogs || [])
    .map((blog) => {
      if (typeof blog === 'string') return null
      return {
        title: blog?.content?.title || '',
        dateOfPublication: blog?.published_at || '',
        previewImage: blog?.content?.previewImage?.filename || '',
        linkToPost: blog.full_slug || '#',
        first_published_at: blog.first_published_at || ''
      }
    })
    .filter(Boolean) as PostItem[]

  return (
    <BlogSection
      titleSection={<StoryblokBlockMapper blocks={title} />}
      posts={blogItems}
      isLightBackground={isLightBackground}
      prioritizeFirstTwoImages={prioritizeFirstTwoImages}
    />
  )
}

export default StoryblokBlogCarouselSection
