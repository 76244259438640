import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, ReviewsCarouselSectionStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import ReviewsSection from '../../components/organisms/ReviewsSection'
import { ReviewItem } from '../../components/organisms/ReviewsSection/types'

const StoryblokReviewsSection: FC<Blok<ReviewsCarouselSectionStoryblok>> = ({
  blok: { reviews, title, seeAllUrl, variant, ...blok }
}) => {
  if (!reviews.length) return null

  const mappedReviews = reviews
    .map((review) => {
      if (typeof review === 'string') return null
      return {
        ...review.content,
        avatar: {
          src: review.content?.avatar.filename,
          alt: review.content?.avatar.alt
        },
        companyLogo: review.content?.companyLogo,
        buttonToSource: <StoryblokBlockMapper blocks={review.content?.buttonToSource} />
      }
    })
    .filter(Boolean) as ReviewItem[]

  return (
    <ReviewsSection
      {...storyblokEditable(blok)}
      title={<StoryblokBlockMapper blocks={title} />}
      reviews={mappedReviews}
      seeAllUrl={seeAllUrl.url}
      variant={variant || 'primary'}
    />
  )
}

export default StoryblokReviewsSection
