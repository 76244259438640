import React, { useMemo } from 'react'
import { cn } from '@dbbs/tailwind-components'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import { Blok, BreakpointsStoryblok } from '../types'

const Breakpoints: React.FC<Blok<BreakpointsStoryblok>> = ({
  blok: { content, contentMd, contentLg, height, heightLg, heightMd }
}) => {
  const hasMd = useMemo(() => contentMd && contentMd?.length > 0, [contentMd])
  const hasLg = useMemo(() => contentLg && contentLg?.length > 0, [contentLg])

  return (
    <>
      <div className={cn('block', height && `h-${height}`, { 'md:hidden': hasMd, 'lg:hidden': !hasMd && hasLg })}>
        <StoryblokBlockMapper blocks={content} />
      </div>
      {hasMd && (
        <div className={cn('hidden md:block', heightMd && `h-${heightMd}`, { 'lg:hidden': hasLg })}>
          <StoryblokBlockMapper blocks={contentMd} />
        </div>
      )}
      {hasLg && (
        <div className={cn('hidden lg:block', heightLg && `h-${heightLg}`)}>
          <StoryblokBlockMapper blocks={contentLg} />
        </div>
      )}
    </>
  )
}

export default Breakpoints
