import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { ISbStoryData } from 'storyblok'
import { Blok, TechCategoryStoryblok, TechnologiesSectionStoryblok, TechnologiesStoryblok } from '../types'
import TechnologiesSection from '../../components/organisms/TechnologiesSection'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import { shuffleArray } from '../../utils/shuffleArray'

const StoryblokTechnologiesSection: FC<Blok<TechnologiesSectionStoryblok>> = ({
  blok: { technologies: technologiesData, title, description, ...blok }
}) => {
  const categories = technologiesData
    .flatMap(
      (item) =>
        (item as ISbStoryData<TechnologiesStoryblok>).content?.category.map((cat) => ({
          uuid: (cat as ISbStoryData<TechCategoryStoryblok>).uuid,
          title: (cat as ISbStoryData<TechCategoryStoryblok>).content.title
        })) || []
    )
    .reduce((acc: { uuid: string; title: string }[], cat) => {
      if (!acc.some((existing) => existing.uuid === cat.uuid)) {
        acc.push(cat)
      }
      return acc
    }, [])

  const technologies = shuffleArray(
    technologiesData.flatMap((item) => {
      const technologyContent = (item as ISbStoryData<TechnologiesStoryblok>).content

      return (
        technologyContent?.category.map((cat) => ({
          uuid: technologyContent._uid,
          technologyName: technologyContent.technologyName,
          technologyImage: technologyContent.technologyImage,
          category: {
            uuid: (cat as ISbStoryData<TechCategoryStoryblok>).uuid,
            title: (cat as ISbStoryData<TechCategoryStoryblok>).content.title
          }
        })) || []
      )
    })
  )
  return (
    <TechnologiesSection
      title={<StoryblokBlockMapper blocks={title} />}
      description={<StoryblokBlockMapper blocks={description} />}
      technologies={technologies}
      categories={categories}
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokTechnologiesSection
