'use client'

import React, { PropsWithChildren } from 'react'
import getStoryblokApi from './common/getStoryblokApi'

type StoryblokProviderProps = PropsWithChildren & {
  isDraftMode?: boolean
}

const StoryblokProvider: React.FC<StoryblokProviderProps> = ({ children, isDraftMode }) => {
  getStoryblokApi(isDraftMode)

  return children
}

export default StoryblokProvider
