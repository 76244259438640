import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@dbbs/tailwind-components'
import { ArrowLeft, ArrowRight } from 'iconoir-react'
import { getTranslations } from 'next-intl/server'
import Typography from '../../atoms/Typography'
import { CaseStudyRelatedCarouselProps } from './types'
import { fetchCaseStudiesRelatedCarousel } from './actions'
import StoryblokBlockMapper from '../../../storyblok/StoryblokBlockMapper'
import CaseStudyCard from '../../molecules/CaseStudyCard'

const CaseStudyRelatedCarousel = async ({ currentCaseStudyId, caseStudyIds }: CaseStudyRelatedCarouselProps) => {
  try {
    const { data: caseStudies } = await fetchCaseStudiesRelatedCarousel({
      currentCaseStudyId,
      caseStudyIds
    })

    const caseStudiesHasStories = caseStudies.stories.length

    if (caseStudiesHasStories === 0) {
      return null
    }

    const t = await getTranslations()

    return (
      <div>
        <Carousel fade opts={{ loop: true }} className="flex flex-col gap-x-sm lg:flex-row lg:gap-md">
          <div className="bg-background-grey shrink-0 gap-md px-x-sm py-lg-mobile flex flex-col justify-between rounded-t-md rounded-b-sm lg:p-lg lg:rounded-l-lg lg:rounded-r-sm lg:basis-1/3">
            <Typography variant="h2" className="uppercase">
              {t('caseStudyRelatedCarousel.title')}
            </Typography>
            {caseStudiesHasStories > 1 && (
              <div className="flex gap-md justify-between md:justify-normal">
                <CarouselPrevious
                  variant="secondary"
                  size="default"
                  className="static -right-0 -top-0 -translate-y-0"
                  iconPrevious={ArrowLeft}
                />

                <CarouselNext
                  variant="secondary"
                  size="default"
                  className="static -left-0 -top-0 -translate-y-0"
                  iconNext={ArrowRight}
                />
              </div>
            )}
          </div>

          <CarouselContent className="basis-2/3">
            {caseStudies.stories.map(({ uuid, content, full_slug }) => {
              const { title, logoCompany, previewImage, caseHighlightsSection } = content.card[0]
              return (
                <CarouselItem key={uuid}>
                  <CaseStudyCard
                    title={title}
                    link={full_slug}
                    logoClientCompany={logoCompany}
                    previewImage={previewImage}
                    caseHighlightsSection={<StoryblokBlockMapper blocks={caseHighlightsSection} />}
                    className="rounded-b-md rounded-t-sm  lg:rounded-r-lg lg:rounded-l-sm"
                  />
                </CarouselItem>
              )
            })}
          </CarouselContent>
        </Carousel>
      </div>
    )
  } catch (error) {
    console.error(error)
    return null
  }
}

export default CaseStudyRelatedCarousel
