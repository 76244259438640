import { FC, PropsWithChildren } from 'react'
import Typography from '../../atoms/Typography'

interface NavigationGroupProps extends PropsWithChildren {
  title?: string
}

const NavigationGroup: FC<NavigationGroupProps> = ({ title, children, ...props }) => (
  <div {...props}>
    {title && (
      <Typography variant="subtitle" className="text-primary mb-4">
        {title}
      </Typography>
    )}
    <div className="flex flex-col gap-3 text-nowrap">{children}</div>
  </div>
)

export default NavigationGroup
