import { cn } from '@dbbs/tailwind-components'
import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { resolveBreakpoints } from '../../utils/resolveBreakpoints'
import { Blok, FlexStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokFlex: FC<Blok<FlexStoryblok>> = ({ blok }) => {
  const { content } = blok

  return (
    <div
      className={cn(
        'flex',
        resolveBreakpoints(blok, 'flexDirection', 'flex-{value}'),
        resolveBreakpoints(blok, 'justifyContent', 'justify-{value}'),
        resolveBreakpoints(blok, 'alignItems', 'items-{value}'),
        resolveBreakpoints(blok, 'gapX', 'gap-x-{value}'),
        resolveBreakpoints(blok, 'gapY', 'gap-y-{value}'),
        resolveBreakpoints(blok, 'flexWrap', 'flex-{value}'),
        resolveBreakpoints(blok, 'height', 'h-{value}')
      )}
      {...storyblokEditable(blok)}
    >
      <StoryblokBlockMapper blocks={content} />
    </div>
  )
}
export default StoryblokFlex
