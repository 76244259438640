import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { cn } from '@dbbs/tailwind-components'
import { Blok, GridStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import { resolveBreakpoints } from '../../utils/resolveBreakpoints'

const StoryblokGrid: FC<Blok<GridStoryblok>> = ({ blok }) => {
  const { content } = blok

  return (
    <div
      {...storyblokEditable(blok)}
      className={cn(
        'grid',
        resolveBreakpoints(blok, 'columnsCount', 'grid-cols-{value}'),
        resolveBreakpoints(blok, 'gapX', 'gap-x-{value}'),
        resolveBreakpoints(blok, 'gapY', 'gap-y-{value}'),
        resolveBreakpoints(blok, 'alignItems', 'items-{value}')
      )}
    >
      <StoryblokBlockMapper blocks={content} />
    </div>
  )
}

export default StoryblokGrid
