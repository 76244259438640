import { FC } from 'react'
import StoryblokTypography from './Typography'
import StoryblokPage from './Page'
import StoryblokContainer from './Container'
import StoryblokButton from './Button'
import StoryblokNavigationLink from './NavigationLink'
import StoryblokNavigationFlyout from './NavigationFlyout'
import NavigationGroup from './NavigationGroup'
import StoryblokHeader from './Header'
import Wrapper from './Wrapper'
import StoryblokFooter from './Footer'
import StoryblokGlobalTemplate from './GlobalTemplate'
import StoryblokGrid from './Grid'
import StoryblokGridItem from './GridItem'
import StoryblokFlex from './Flex'
import StoryblokFlexItem from './FlexItem'
import StoryblokTabs from './Tabs'
import StoryblokImage from './Image'
import Breakpoints from './Breakpoints'
import Carousel from './Carousel'
import StoryblokSpace from './Space'
import StoryblokTechCard from './TechCard'
import StoryblokTile from './Tile'
import StoryblokContactUsForm from './ContactUsForm'
import StoryblokTimeReductionChart from './TimeReductionChart'
import StoryblokArcProgressChart from './ArcProgressChart'
import StoryblokLogoClick from './LogoClick'
import StoryblokHero from './Hero'
import StoryblokPerformanceCard from './PerformanceCard'
import StoryblokBlogCarouselSection from './BlogCarouselSection'
import StoryblokReviewsSection from './ReviewsSection'
import CarouselNavigations from './CarouselNavigations'
import StoryblokAccordion from './Accordion'
import StoryblokLottieAnimation from './LottieAnimation'
import StoryblokRichText from './RichText'
import StoryblokSearchInput from './SearchInput'
import StorybloInsightsGridSection from './InsightsGridSection'
import StoryblokBreadcrumbs from './Breadcrumbs'
import StoryblokRequestChecklistForm from './RequestChecklistForm'
import StoryblokRating from './Rating'
import StoryblokVideoAccordion from './VideoAccordion'
import StoryblokPreBuiltSolutionsHero from './PreBuiltSolutionsHero'
import StoryblokTable from './Table'
import StoryblokTechnologiesSection from './TechnologiesSection'
import StoryblokConsentSettingsTrigger from './ConsentSettingsTrigger'
import StoryblokCaseStudyFiltersAndList from './CaseStudyFiltersAndList'
import StoryblokCaseStudyCarouselSection from './CaseStudyCarouselSection'
import StoryblokCaseStudyCarousel from './CaseStudyCarousel'
import StoryblokCaseStudySlide from './CaseStudySlide'
import StoryblokCaseStudyRelatedCarousel from './CaseStudyRelatedCarousel'
import StoryblokCaseStudyPage from './CaseStudyPage'
import StoryblokCaseStudyHero from './CaseStudyHero'
import StoryblokCompanyHistoryCarousel from './CompanyHistoryCarousel'
import StoryblokVideo from './Video'
import StoryblokCaseStudyPanel from './CaseStudyPanel'
import StoryblokServicesDetailsList from './ServicesDetailsList'
import StoryblokRelationCarouselPicker from './RelationCarouselPicker'
import StoryblokStaffCarousel from './StaffCarousel'
import StoryblokRelationCarousel from './RelationCarusel'
import StoryblokProcessStepsCarousel from './ProcessStepsCarousel'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const components: { [key: string]: FC<any> } = {
  page: StoryblokPage,
  typography: StoryblokTypography,
  container: StoryblokContainer,
  button: StoryblokButton,
  header: StoryblokHeader,
  navigationLink: StoryblokNavigationLink,
  navigationFlyout: StoryblokNavigationFlyout,
  navigationGroup: NavigationGroup,
  wrapper: Wrapper,
  footer: StoryblokFooter,
  globalTemplate: StoryblokGlobalTemplate,
  grid: StoryblokGrid,
  gridItem: StoryblokGridItem,
  flex: StoryblokFlex,
  flexItem: StoryblokFlexItem,
  space: StoryblokSpace,
  tabs: StoryblokTabs,
  image: StoryblokImage,
  breakpoints: Breakpoints,
  carousel: Carousel,
  techCard: StoryblokTechCard,
  tile: StoryblokTile,
  contactUsForm: StoryblokContactUsForm,
  timeReductionChart: StoryblokTimeReductionChart,
  arcProgressChart: StoryblokArcProgressChart,
  logoClick: StoryblokLogoClick,
  hero: StoryblokHero,
  performanceCard: StoryblokPerformanceCard,
  blogCarouselSection: StoryblokBlogCarouselSection,
  reviewsCarouselSection: StoryblokReviewsSection,
  carouselNavigations: CarouselNavigations,
  accordion: StoryblokAccordion,
  lottieAnimation: StoryblokLottieAnimation,
  richText: StoryblokRichText,
  searchInput: StoryblokSearchInput,
  insightsGridSection: StorybloInsightsGridSection,
  breadcrumbs: StoryblokBreadcrumbs,
  requestChecklistForm: StoryblokRequestChecklistForm,
  rating: StoryblokRating,
  videoAccordionSection: StoryblokVideoAccordion,
  preBuiltSolutionsHero: StoryblokPreBuiltSolutionsHero,
  table: StoryblokTable,
  technologiesSection: StoryblokTechnologiesSection,
  consentSettingsTrigger: StoryblokConsentSettingsTrigger,
  caseStudyFiltersAndList: StoryblokCaseStudyFiltersAndList,
  caseStudyCarouselSection: StoryblokCaseStudyCarouselSection,
  caseStudyCarousel: StoryblokCaseStudyCarousel,
  caseStudySlide: StoryblokCaseStudySlide,
  caseStudyRelatedCarousel: StoryblokCaseStudyRelatedCarousel,
  caseStudyPage: StoryblokCaseStudyPage,
  caseStudyHero: StoryblokCaseStudyHero,
  companyHistoryCarousel: StoryblokCompanyHistoryCarousel,
  video: StoryblokVideo,
  caseStudyPanel: StoryblokCaseStudyPanel,
  servicesDetailsList: StoryblokServicesDetailsList,
  relationCarouselPicker: StoryblokRelationCarouselPicker,
  staffCarousel: StoryblokStaffCarousel,
  relationCarousel: StoryblokRelationCarousel,
  processStepsCarousel: StoryblokProcessStepsCarousel
}

export default components
