import { Star } from 'iconoir-react'
import { RatingProps } from './types'

const Rating = ({ totalStars = 5, rating }: RatingProps) => {
  const validRating = Math.min(totalStars, Math.max(0, rating))
  const fullStars = Math.floor(validRating)
  const hasHalfStar = validRating % 1 !== 0
  const emptyStars = totalStars - fullStars - (hasHalfStar ? 1 : 0)

  const fullStarElements = Array.from({ length: fullStars }, (_, i) => (
    <Star key={`full-${i}`} className="text-badge-2 fill-badge-2" />
  ))
  const halfStarElement = hasHalfStar && (
    <div className="relative inline-block w-6 h-6" key="half-star">
      <Star className="absolute text-dark fill-dark top-0 left-0" />

      <div className="absolute top-0 left-0 w-1/2 h-full overflow-hidden">
        <Star className="fill-badge-2 text-badge-2" />
      </div>
    </div>
  )
  const emptyStarElements = Array.from({ length: emptyStars }, (_, i) => (
    <Star key={`empty-${i}`} className="text-dark fill-dark" />
  ))
  return (
    <div className="flex gap-1">
      {fullStarElements}
      {halfStarElement}
      {emptyStarElements}
    </div>
  )
}

export default Rating
