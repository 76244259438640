import Image from 'next/image'
import Link from 'next/link'
import { Button, cn } from '@dbbs/tailwind-components'
import { BrightCrown, Play } from 'iconoir-react'
import { useTranslations } from 'next-intl'
import Typography from '../../atoms/Typography'
import Space from '../../atoms/Space'
import { CaseStudySlideProps, SectionProps } from './types'

const SectionPartOfSlide = ({ heading, children, className }: SectionProps) => (
  <div className={className}>
    <Typography variant="h5" weight="semibold">
      {heading}
    </Typography>
    <Space size="xxSmall" />
    {children}
    <Space size="small" />
  </div>
)

const CaseStudySlide = ({
  watchDemoUrl,
  learnMoreUrl,
  challengeContent,
  imageCase,
  result,
  solutionContent,
  title,
  isLightBackground
}: CaseStudySlideProps) => {
  const buttonVariant = isLightBackground ? 'secondary' : 'secondaryDarkBG'
  const textColorClasses = isLightBackground ? 'text-dark [&_*]:text-dark' : 'text-white [&_*]:text-white'
  const t = useTranslations('caseStudySlide')

  return (
    <div className="grid grid-cols-1 gap-md lg:grid-cols-2 h-full">
      <div className="order-last lg:order-none flex justify-center items-center">
        <Image
          src={imageCase?.filename ?? ''}
          alt={imageCase?.alt ?? ''}
          width={462}
          height={488}
          className="aspect-[0.90/1] p-5 drop-shadow-caseStudyImage"
        />
      </div>

      <div className="flex flex-col gap-md lg:justify-between">
        <div>
          <div className={cn('uppercase', textColorClasses)}>
            {title}
            <Space size="small" />
          </div>

          <SectionPartOfSlide heading={t('challenge')} className={textColorClasses}>
            {challengeContent}
          </SectionPartOfSlide>

          <SectionPartOfSlide heading={t('solution')} className={textColorClasses}>
            {solutionContent}
          </SectionPartOfSlide>

          <div>
            <Typography variant="h5" weight="semibold" className={textColorClasses}>
              {t('result')}
            </Typography>

            <Space size="xxSmall" />
            <div className="px-5 py-4 flex items-center gap-2 bg-gradient-to-r from-badge-2 to-badge-1 rounded-sm text-dark">
              <BrightCrown className="shrink-0" />
              {result}
            </div>
          </div>
        </div>
        <div>
          <div className="flex gap-md flex-col sm:flex-row">
            {learnMoreUrl && (
              <Link href={learnMoreUrl}>
                <Button variant={buttonVariant} className="w-full">
                  <Typography variant="link">{t('exploreMore')}</Typography>
                </Button>
              </Link>
            )}

            {watchDemoUrl && (
              <Link href={watchDemoUrl}>
                <Button variant={buttonVariant} className="w-full">
                  <div className="gap-2 relative">
                    <Play className="absolute top-1/2 left-0 transform -translate-y-1/2" />
                    <Typography variant="link" className="ml-9">
                      {t('watchDemo')}
                    </Typography>
                  </div>
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseStudySlide
