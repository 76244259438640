import { FC } from 'react'
import { storyblokEditable, StoryblokServerComponent } from '@storyblok/react/rsc'
import { Blok, RelationCarouselPickerStoryblok } from '../types'

const StoryblokRelationCarouselPiker: FC<Blok<RelationCarouselPickerStoryblok>> = ({ blok }) => {
  try {
    if (typeof blok.carousel[0] === 'string') {
      console.error('Check relationCarouselPicker.carousel in resolveRelations')
      return null
    }

    return <StoryblokServerComponent {...storyblokEditable(blok)} blok={blok.carousel[0].content} />
  } catch (error) {
    console.error(error)
    return null
  }
}
export default StoryblokRelationCarouselPiker
