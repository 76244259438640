import React, { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator
} from '@dbbs/tailwind-components'
import Link from 'next/link'
import { Blok, BreadcrumbsStoryblok } from '../types'
import Typography from '../../components/atoms/Typography'
import { extractStoryblokLink } from '../../utils/extractStoryblokLink'
import { generateJsonLdBreadcrumbList } from '../../utils/generateJsonLdSchemas'
import JsonLd from '../../components/atoms/JsonLd'

const StoryblokBreadcrumbs: FC<Blok<BreadcrumbsStoryblok>> = ({ blok }) => (
  <div {...storyblokEditable(blok)} className="z-30">
    <Breadcrumb className={`text-${blok.color}`}>
      <BreadcrumbList className="gap-1 sm:gap-1">
        {blok.items.map((item, index) => (
          <React.Fragment key={item._uid}>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                {index === blok.items.length - 1 ? (
                  <span itemProp="name">
                    <Typography variant="footnote">{item.title}</Typography>
                  </span>
                ) : (
                  <Link itemProp="item" href={extractStoryblokLink(item.link)} className="hover:underline">
                    <span itemProp="name">
                      <Typography variant="footnote">{item.title}</Typography>
                    </span>
                  </Link>
                )}
              </BreadcrumbLink>
              <meta itemProp="position" content={`${index + 1}`} />
            </BreadcrumbItem>
            {index < blok.items.length - 1 && (
              <BreadcrumbSeparator>
                <Typography variant="footnote">/</Typography>
              </BreadcrumbSeparator>
            )}
          </React.Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
    <JsonLd
      schemas={[
        generateJsonLdBreadcrumbList({
          breadcrumbs: blok.items.map((breadcrumb) => ({
            name: breadcrumb.label,
            url: extractStoryblokLink(breadcrumb.link)
          }))
        })
      ]}
    />
  </div>
)
export default StoryblokBreadcrumbs
