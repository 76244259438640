import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@dbbs/tailwind-components'
import { ArrowLeft, ArrowRight } from 'iconoir-react'
import Space from '../../atoms/Space'
import { CaseStudyCarouselProps } from './types'

const CaseStudyCarousel = ({
  title,
  slides,
  playOnInit,
  stopOnInteraction,
  stopOnMouseEnter,
  loop,
  dragFree,
  autoScroll,
  fadeEffect,
  dragScrolling,
  controlButtonVariant = 'secondary'
}: CaseStudyCarouselProps) => (
  <div>
    {title && <div className="max-w-[500px]">{title}</div>}

    <Carousel
      opts={{ loop, dragFree, watchDrag: dragScrolling }}
      autoScroll={autoScroll}
      fade={fadeEffect}
      autoScrollOptions={{ playOnInit, stopOnInteraction, stopOnMouseEnter }}
    >
      <CarouselContent>
        {slides.map((item, index) => (
          <CarouselItem key={`carousel-item-${index}`}>{item}</CarouselItem>
        ))}
      </CarouselContent>
      <div className="block lg:hidden">
        <Space size="medium" />
      </div>
      <div className="flex gap-md justify-between lg:justify-normal lg:absolute lg:bottom-0 lg:left-0">
        <CarouselPrevious
          variant={controlButtonVariant}
          size="default"
          className="static -right-0 -top-0 -translate-y-0"
          iconPrevious={ArrowLeft}
        />

        <CarouselNext
          variant={controlButtonVariant}
          size="default"
          className="static -left-0 -top-0 -translate-y-0"
          iconNext={ArrowRight}
        />
      </div>
    </Carousel>
  </div>
)

export default CaseStudyCarousel
