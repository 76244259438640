import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { cn } from '@dbbs/tailwind-components'
import Typography from '../../components/atoms/Typography'
import { Blok, TypographyStoryblok } from '../types'

const StoryblokTypography: FC<Blok<TypographyStoryblok>> = ({ blok }) => {
  const { text, variant, weight, renderAs, nested, color, withBulletPoint } = blok

  return (
    <Typography
      {...storyblokEditable(blok)}
      variant={variant || 'body'}
      weight={weight || 'normal'}
      renderAs={renderAs || 'p'}
      className={cn(`text-${color}`, {
        'list-item': withBulletPoint
      })}
    >
      {text}
      {nested?.map((nestedBlok: TypographyStoryblok) => (
        <StoryblokTypography key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Typography>
  )
}

export default StoryblokTypography
