import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@dbbs/tailwind-components'
import { ArrowRight, ArrowLeft } from 'iconoir-react'
import ProcessStepCard from '../../molecules/ProcessStepCard'
import { ProcessStepsCarouselProps } from './types'
import Space from '../../atoms/Space'

const ProcessStepsCarousel = ({ steps }: ProcessStepsCarouselProps) => (
  <Carousel opts={{ align: 'start' }}>
    <CarouselContent className="-ml-0 gap-md-deluxe mx-4 lg:mx-16">
      {steps.map((step, index) => (
        <CarouselItem key={`${step.title}-${index}`} className="pl-0 basis-full sm:basis-[60%] lg:basis-[35%]">
          <ProcessStepCard {...step} />
        </CarouselItem>
      ))}
    </CarouselContent>
    <Space size="medium" />
    <div className="flex justify-between sm:justify-normal  gap-md px-x-sm lg:px-lg">
      <CarouselPrevious
        variant="secondary"
        size="default"
        className="static -right-0 -top-0 -translate-y-0"
        iconPrevious={ArrowLeft}
      />

      <CarouselNext
        variant="secondary"
        size="default"
        className="static -left-0 -top-0 -translate-y-0"
        iconNext={ArrowRight}
      />
    </div>
  </Carousel>
)

export default ProcessStepsCarousel
