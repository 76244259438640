import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react'
import { Blok, ServicesDetailsListStoryblok } from '../types'
import ServicesDetailsList from '../../components/organisms/ServicesDetailsList'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokServicesDetailsList: FC<Blok<ServicesDetailsListStoryblok>> = ({
  blok: { title, content, ...blok }
}) => {
  try {
    const mappedContent = content?.map((item) => ({
      title: item.title ?? '',
      _uid: item._uid ?? '',
      content: <StoryblokBlockMapper blocks={item.content} />
    }))

    return <ServicesDetailsList title={title ?? ''} content={mappedContent ?? []} {...storyblokEditable(blok)} />
  } catch (error) {
    console.error(error)
    return null
  }
}

export default StoryblokServicesDetailsList
