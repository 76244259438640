import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { cn } from '@dbbs/tailwind-components'
import { Blok, GridItemStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import { resolveBreakpoints } from '../../utils/resolveBreakpoints'

const StoryblokGridItem: FC<Blok<GridItemStoryblok>> = ({ blok }) => {
  const { content } = blok

  return (
    <div
      {...storyblokEditable(blok)}
      className={cn(
        resolveBreakpoints(blok, 'columnSpan', 'col-{value}'),
        resolveBreakpoints(blok, 'columnStart', 'col-start-{value}'),
        resolveBreakpoints(blok, 'rowSpan', 'row-{value}'),
        resolveBreakpoints(blok, 'rowStart', 'row-start-{value}'),
        resolveBreakpoints(blok, 'justifySelf', 'justify-self-{value}')
      )}
    >
      <StoryblokBlockMapper blocks={content} />
    </div>
  )
}

export default StoryblokGridItem
