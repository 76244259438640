import React from 'react'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import { Blok, CaseStudyPageStoryblok } from '../types'

const StoryblokCaseStudyPage: React.FC<Blok<CaseStudyPageStoryblok>> = ({ blok }) => {
  const modifiedBlok = {
    ...blok,
    parentProps: {
      ...(blok.industry && { industryIds: blok.industry }),
      ...(blok.service && { serviceIds: blok.service }),
      ...(blok._uid && { currentCaseStudyId: blok._uid })
    }
  }

  return (
    <main className="grow" {...storyblokEditable(blok)}>
      {modifiedBlok.body?.map((nestedBlok) => (
        <StoryblokServerComponent
          blok={{ ...nestedBlok, parentProps: modifiedBlok?.parentProps }}
          key={nestedBlok._uid}
        />
      ))}
    </main>
  )
}

export default StoryblokCaseStudyPage
