import { Button, cn } from '@dbbs/tailwind-components'
import { FC } from 'react'
import Link from 'next/link'
import { storyblokEditable } from '@storyblok/react/rsc'
import { cva } from 'class-variance-authority'
import Image from 'next/image'
import { Blok, ButtonStoryblok } from '../types'
import { Dimensions } from '../../types/designSystem'
import Typography from '../../components/atoms/Typography'

const paddingXDimensions = {
  sm: 'px-4',
  md: 'px-5',
  lg: 'px-6',
  none: 'px-0'
}

const paddingYDimensions = {
  sm: 'py-4',
  md: 'py-5',
  lg: 'py-6',
  none: 'py-0'
}

const button = cva([], {
  variants: {
    paddingX: paddingXDimensions,
    paddingY: paddingYDimensions
  },
  defaultVariants: {
    paddingX: 'md',
    paddingY: 'sm'
  }
})

const StoryblokButton: FC<Blok<ButtonStoryblok>> = ({
  blok: {
    title,
    link,
    variant,
    paddingX,
    paddingY,
    textVariant,
    text,
    width,
    textColor,
    borderColor,
    icon,
    underlineOnHover,
    linkPreserveHashScroll,
    ...blok
  }
}) => {
  const widthSize = width ? `w-${width}` : ''
  const ButtonInstance = (
    <Button
      variant={variant || 'primary'}
      className={cn(
        button({ paddingX: paddingX as Dimensions, paddingY: paddingY as Dimensions }),
        widthSize,
        borderColor && `border-${borderColor}`,
        textColor && `text-${textColor}`,
        underlineOnHover && 'hover:underline'
      )}
      tabIndex={link ? -1 : undefined}
    >
      {icon?.filename && (
        <Image
          src={icon?.filename}
          className={cn({ 'mr-xx-sm': title }, { 'hover:opacity-70': !title })}
          alt={icon?.alt || 'Button icon'}
          width={24}
          height={24}
          unoptimized
        />
      )}
      <Typography variant={textVariant || 'link'}>{title}</Typography>
    </Button>
  )

  return link ? (
    <Link {...storyblokEditable(blok)} href={link.url} target={link?.target} scroll={linkPreserveHashScroll}>
      {ButtonInstance}
    </Link>
  ) : (
    ButtonInstance
  )
}

export default StoryblokButton
