import { storyblokInit as sbInit } from '@storyblok/js'
import { apiPlugin } from '@storyblok/react/rsc'
import { StoryblokClient } from '@storyblok/react'

const publicApiToken = process.env.NEXT_PUBLIC_STORYBLOK_PUBLIC_API_TOKEN

const publicStoryblokApi = (): StoryblokClient => {
  const { storyblokApi } = sbInit({
    accessToken: publicApiToken,
    use: [apiPlugin]
  })

  if (!storyblokApi) {
    throw new Error('Storyblok API not initialized')
  }

  return storyblokApi
}

export default publicStoryblokApi
