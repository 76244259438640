import Link from 'next/link'
import Image, { ImageProps } from 'next/image'
import dayjs from 'dayjs'
import { cn } from '@dbbs/tailwind-components'
import Typography from '../../atoms/Typography'
import { BlogCarouselCardProps } from './type'
import Space from '../../atoms/Space'
import InViewImage from '../../atoms/InViewImage'

const BlogCarouselCard = ({
  link,
  dateOfPublication,
  title,
  srcImg,
  isLightBackground = false,
  className,
  priority = false,
  intersectionLazy = false,
  contentContainerId
}: BlogCarouselCardProps) => {
  const imageProps: ImageProps = {
    src: srcImg,
    alt: title,
    width: 422,
    height: 300,
    className: 'w-full rounded object-cover aspect-[1.4/1]'
  }

  return (
    <Link href={`/${link}`}>
      <div className={cn('hover:brightness-110 transition group', className)}>
        {intersectionLazy ? (
          <InViewImage
            {...imageProps}
            priority={priority}
            fallbackClassName="w-full bg-line-grey aspect-[1.4/1] rounded"
            contentContainerId={contentContainerId}
            intersectionOptions={{
              rootMargin: '0px 300px'
            }}
          />
        ) : (
          <Image {...imageProps} />
        )}
        <Space size="medium" />
        <Typography
          className={cn('text-line-grey group-hover:text-primary transition', {
            'text-secondary-grey': isLightBackground
          })}
        >
          {dayjs(dateOfPublication).format('DD MMM. YYYY')}
        </Typography>
        <Space size="xSmall" />
        <Typography
          variant="h5"
          className={cn('text-white text-pretty line-clamp-2 group-hover:text-primary transition', {
            'text-dark': isLightBackground
          })}
        >
          {title}
        </Typography>
      </div>
    </Link>
  )
}

export default BlogCarouselCard
