import dayjs from 'dayjs'
import {
  FAQPage,
  BreadcrumbList,
  WebPage,
  Service,
  BlogPosting,
  Person,
  ItemList,
  CreativeWork,
  CollectionPage,
  WithContext
} from 'schema-dts'
import {
  JsonLdAuthorItemList,
  JsonLdBlogPosting,
  JsonLdBreadcrumbList,
  JsonLdCollectionPage,
  JsonLdCreativeWork,
  JsonLdFAQPage,
  JsonLdPerson,
  JsonLdService,
  JsonLdWebPage
} from '../types/jsonLdSchemas'
import getDomainName from './getDomainName'

export const generateJsonLdFAQPage = ({ faqs }: JsonLdFAQPage): WithContext<FAQPage> => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: faqs.map((faq) => ({
    '@type': 'Question',
    name: faq.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: faq.answer
    }
  }))
})

export const generateJsonLdBreadcrumbList = ({ breadcrumbs }: JsonLdBreadcrumbList): WithContext<BreadcrumbList> => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: breadcrumbs.map((breadcrumb, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: breadcrumb.name,
    item: `${getDomainName()}${breadcrumb.url}`
  }))
})

export const generateJsonLdWebPage = ({ name, description, url }: JsonLdWebPage): WithContext<WebPage> => ({
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  name,
  description,
  url: `${getDomainName()}/${url}`,
  primaryImageOfPage: {
    '@type': 'ImageObject',
    url: 'https://a.storyblok.com/f/303475/103x40/05ace2bfa7/dbbs-logo.svg'
  },
  isPartOf: {
    '@type': 'WebSite',
    url: `${getDomainName()}/`,
    name: 'Software Development Company - DBB Software',
    description:
      'DBB Software trusted IT software development company, reducing app launch times by 50% with our platform, and ensuring robust cloud setup with certified AWS expertise.',
    inLanguage: 'en',
    publisher: {
      '@type': 'Organization',
      url: 'https://dbbsoftware.com/',
      name: 'DBB Software',
      logo: 'https://a.storyblok.com/f/303475/103x40/05ace2bfa7/dbbs-logo.svg'
    }
  }
})

export const generateJsonLdService = ({ name, description, serviceType }: JsonLdService): WithContext<Service> => ({
  '@context': 'https://schema.org',
  '@type': 'Service',
  name,
  description,
  provider: {
    '@type': 'Organization',
    name: 'DBB Software',
    url: `${getDomainName()}/`
  },
  serviceType,
  areaServed: {
    '@type': 'Country',
    name: 'USA'
  },
  offers: {
    '@type': 'Offer',
    price: 'Contact for pricing',
    priceCurrency: 'USD',
    availability: 'https://schema.org/InStock'
  }
})

export const generateJsonLdBlogPosting = ({
  headline,
  description,
  authorName,
  authorUrl,
  dateModified,
  datePublished,
  imageHeight,
  imageSrc,
  imageWidth,
  url,
  tags
}: JsonLdBlogPosting): WithContext<BlogPosting> => ({
  '@context': 'https://schema.org',
  '@type': 'BlogPosting',
  headline,
  description,
  author: {
    '@type': 'Person',
    name: authorName,
    url: `${getDomainName()}/${authorUrl}`
  },
  publisher: {
    '@type': 'Organization',
    name: 'DBBSoftware',
    logo: {
      '@type': 'ImageObject',
      url: 'https://a.storyblok.com/f/303475/103x40/05ace2bfa7/dbbs-logo.svg'
    }
  },
  datePublished: dayjs(datePublished).format('YYYY-MM-DD'),
  dateModified: dayjs(dateModified).format('YYYY-MM-DD'),
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': `${getDomainName()}/${url}`
  },
  image: {
    '@type': 'ImageObject',
    url: imageSrc,
    width: imageWidth.toString(),
    height: imageHeight.toString()
  },
  articleSection: tags.join(', '),
  inLanguage: 'en-US'
})

export const generateJsonLdPerson = ({
  name,
  description,
  image,
  jobTitle,
  linkedin,
  url
}: JsonLdPerson): WithContext<Person> => ({
  '@context': 'https://schema.org',
  '@type': 'Person',
  name,
  url: `${getDomainName()}/${url}`,
  jobTitle,
  worksFor: {
    '@type': 'Organization',
    name: 'DBB Software',
    url: `${getDomainName()}/`
  },
  image,
  sameAs: [linkedin],
  description,
  knowsAbout: ['Software Development', 'Cloud Computing', 'Machine Learning', 'E-commerce Solutions']
})

export const generateJsonLdItemList = ({ items, name }: JsonLdAuthorItemList): WithContext<ItemList> => ({
  '@context': 'https://schema.org',
  '@type': 'ItemList',
  name,
  itemListElement: items.map((item) => ({
    '@type': 'BlogPosting',
    headline: item.headline,
    url: `${getDomainName()}/${item.url}`,
    datePublished: dayjs(item.datePublished).format('YYYY-MM-DD'),
    author: {
      '@type': 'Person',
      name: item.authorName,
      url: item.authorUrl
    },
    publisher: {
      '@type': 'Organization',
      name: 'DBB Software',
      url: `${getDomainName()}/`
    }
  }))
})

export const generateJsonLdCreativeWork = ({
  name,
  description,
  url,
  datePublished,
  dateModified,
  image,
  applicationCategory,
  operatingSystem
}: JsonLdCreativeWork): WithContext<CreativeWork> => ({
  '@context': 'https://schema.org',
  '@type': 'CreativeWork',
  '@id': `${getDomainName()}/${url}`,
  name,
  url: `${getDomainName()}/${url}`,
  description,
  datePublished: dayjs(datePublished).format('YYYY-MM-DD'),
  dateModified,
  author: {
    '@type': 'Organization',
    name: 'DBB Software',
    url: 'https://dbbsoftware.com'
  },
  publisher: {
    '@type': 'Organization',
    name: 'DBB Software',
    logo: {
      '@type': 'ImageObject',
      url: 'https://a.storyblok.com/f/303475/103x40/05ace2bfa7/dbbs-logo.svg'
    }
  },
  about: {
    '@type': applicationCategory?.replace(' ', '') as keyof CreativeWork['about'],
    name,
    applicationCategory,
    operatingSystem
  },
  ...(image && { image })
})

export const generateJsonLdCollectionPage = ({
  name,
  description,
  url,
  hasPart
}: JsonLdCollectionPage): WithContext<CollectionPage> => ({
  '@context': 'https://schema.org',
  '@type': 'CollectionPage',
  name,
  description,
  url: `${getDomainName()}/${url}`,
  hasPart
})
