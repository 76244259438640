import { ISbResult } from '@storyblok/react/rsc'
import { FetchInsights } from './types'
import publicStoryblokApi from '../../../storyblok/common/publicStoryblokApi'

export const fetchAllCategoriesInInsightsGridSection = async () => {
  try {
    const storyblokApi = publicStoryblokApi()

    const response = await storyblokApi.get('cdn/stories', {
      starts_with: 'insights/category',
      content_type: 'category'
    })
    return response
  } catch (error) {
    console.error(error)
    return {
      data: {}
    }
  }
}

export const fetchInsightsInInsightsGridSection = async ({
  isEnabled = false,
  perPage = 8,
  categoryId,
  authorId,
  numberPage
}: FetchInsights): Promise<ISbResult> => {
  try {
    const storyblokApi = publicStoryblokApi()

    const response = await storyblokApi.get(
      'cdn/stories',
      {
        starts_with: 'insights/',
        content_type: 'article',
        sort_by: 'sort_by_date:desc',
        version: isEnabled ? 'draft' : 'published',
        ...(numberPage && { page: numberPage }),
        ...(perPage && { per_page: perPage }),
        filter_query: {
          ...(categoryId && {
            category: {
              any_in_array: categoryId
            }
          }),
          ...(authorId && {
            author: {
              any_in_array: authorId
            }
          })
        }
      },
      { cache: isEnabled ? 'no-cache' : 'default' }
    )

    return response
  } catch (error) {
    console.error(error)
    return {
      data: {
        stories: []
      },
      headers: new Headers(),
      perPage: 0,
      total: 0
    }
  }
}
