import { FC } from 'react'
import { storyblokEditable, StoryblokServerComponent } from '@storyblok/react/rsc'
import { Blok, CaseStudyCarouselSectionStoryblok } from '../types'

const StoryblokCaseStudyCarouselSection: FC<Blok<CaseStudyCarouselSectionStoryblok>> = ({ blok }) => {
  if (typeof blok.selectedCarousel[0] === 'string') {
    console.error('Check caseStudyCarouselSection.selectedCarousel in resolveRelations')
    return null
  }

  return <StoryblokServerComponent blok={blok.selectedCarousel[0].content} {...storyblokEditable(blok)} />
}

export default StoryblokCaseStudyCarouselSection
