import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react'
import Link from 'next/link'
import { cn } from '@dbbs/tailwind-components'
import { Blok, TileStoryblok } from '../types'
import Tile from '../../components/molecules/Tile'
import Icon from '../../components/atoms/Icon'

const StoryblokTile: FC<Blok<TileStoryblok>> = ({ blok: { title, icon, link, isShowIcon, variant, ...blok } }) => {
  const target = link?.linktype === 'url' ? '_blank' : undefined

  const TileInstance = (
    <Tile
      title={title}
      showIcon={isShowIcon}
      icon={icon ? <Icon name={icon as string} /> : undefined}
      className={cn('h-full', {
        'border-white-50 *:text-white hover:border-white *:active:text-white active:border-primary':
          variant === 'darkBg'
      })}
    />
  )

  return link?.url ? (
    <Link {...storyblokEditable(blok)} href={link.url} type={target}>
      {TileInstance}
    </Link>
  ) : (
    TileInstance
  )
}

export default StoryblokTile
