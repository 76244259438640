import { cn } from '@dbbs/tailwind-components'
import { storyblokEditable } from '@storyblok/react/rsc'
import Image from 'next/image'
import { FC } from 'react'
import { resolveBreakpoints } from '../../utils/resolveBreakpoints'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import { Blok, WrapperStoryblok } from '../types'

const StoryblokWrapper: FC<Blok<WrapperStoryblok>> = ({ blok }) => {
  const {
    content,
    id,
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    backgroundSize,
    backgroundRepeat,
    borderBrightening,
    maxWidth,
    overflow,
    height,
    marginLeftIsNegative,
    marginRightIsNegative,
    marginTopIsNegative,
    marginBottomIsNegative,
    parentProps,
    border,
    borderColor,
    bottomShadow,
    bottomShadowColor,
    minWidth,
    backgroundImagePriority = false,
    bottomShadowPosition
  } = blok

  return (
    <div
      {...storyblokEditable(blok)}
      className={cn(
        resolveBreakpoints(blok, 'paddingTop', 'pt-{value}'),
        resolveBreakpoints(blok, 'paddingBottom', 'pb-{value}'),
        resolveBreakpoints(blok, 'paddingLeft', 'pl-{value}'),
        resolveBreakpoints(blok, 'paddingRight', 'pr-{value}'),
        resolveBreakpoints(blok, 'borderRadiusTopLeft', 'rounded-tl-{value}'),
        resolveBreakpoints(blok, 'borderRadiusTopRight', 'rounded-tr-{value}'),
        resolveBreakpoints(blok, 'borderRadiusBottomLeft', 'rounded-bl-{value}'),
        resolveBreakpoints(blok, 'borderRadiusBottomRight', 'rounded-br-{value}'),
        resolveBreakpoints(blok, 'height', 'h-{value}'),
        resolveBreakpoints(blok, 'marginRight', `${marginRightIsNegative ? '-mr-{value}' : 'mr-{value}'}`),
        resolveBreakpoints(blok, 'marginLeft', `${marginLeftIsNegative ? '-ml-{value}' : 'ml-{value}'}`),
        resolveBreakpoints(blok, 'marginTop', `${marginTopIsNegative ? '-mt-{value}' : 'mt-{value}'}`),
        resolveBreakpoints(blok, 'marginBottom', `${marginBottomIsNegative ? '-mb-{value}' : 'mb-{value}'}`),
        resolveBreakpoints(blok, 'position', '{value}'),
        resolveBreakpoints(blok, 'top', 'top-{value}'),
        backgroundSize,
        backgroundRepeat,
        maxWidth && `max-w-${maxWidth}`,
        minWidth && `min-w-${minWidth}`,
        height && `h-${height}`,
        backgroundColor && `bg-${backgroundColor}`,
        overflow && `overflow-${overflow}`,
        border && 'border',
        borderColor && `border-${borderColor}`,
        {
          // eslint-disable-next-line quotes
          "before:absolute before:content-[''] before:top-0 before:left-0 before:w-40 before:h-full before:z-10 before:pointer-events-none before:bg-gradient-to-r before:from-white before:via-white/[0.1] before:to-transparent after:absolute after:content-[''] after:top-0 after:right-0 after:w-40 after:h-full after:z-10 after:pointer-events-none after:bg-gradient-to-l after:from-white after:via-white/[0.1] after:to-transparent":
            borderBrightening,
          'scroll-mt-20': id,
          'overflow-hidden': backgroundImage?.filename
        },
        'relative'
      )}
      id={id}
    >
      {backgroundImage?.filename && (
        <Image
          src={backgroundImage?.filename}
          fill
          draggable={false}
          priority={backgroundImagePriority}
          className={cn(
            'absolute top-0 left-0 h-full w-full object-cover z-[1] select-none',
            backgroundPosition && `object-${backgroundPosition}`
          )}
          alt="Background Image"
        />
      )}
      <div
        className={cn('relative z-[2]', resolveBreakpoints(blok, 'height', 'h-{value}'), {
          'z-[2]': backgroundImage?.filename
        })}
      >
        <StoryblokBlockMapper blocks={content} parentProps={parentProps} />
      </div>
      {bottomShadow && (
        <div
          className={cn(
            'absolute rounded-[100%] overflow-hidden blur-2xl left-1/2 -translate-x-1/2 w-[120%] z-[20] h-[103px] bg-text-gradient',
            {
              [`bg-${bottomShadowColor}`]: bottomShadowColor
            }
          )}
          style={{
            ...(bottomShadowPosition !== undefined && { bottom: `${bottomShadowPosition}px` })
          }}
        />
      )}
    </div>
  )
}

export default StoryblokWrapper
