import Image from 'next/image'
import { getTranslations } from 'next-intl/server'
import Space from '../../atoms/Space'
import Typography from '../../atoms/Typography'
import { CaseStudyHeroProps } from './types'
import { fetchIndustryByUUId, fetchServiceByUUId } from './action'
import ProjectSummaryItem from './ProjectSummaryItem'

const CaseStudyHero = async ({
  title,
  description,
  team,
  projectState,
  flagImage,
  country,
  industryUUId,
  previewImage,
  serviceUUId,
  breadcrumbs
}: CaseStudyHeroProps) => {
  const t = await getTranslations('caseStudyHero')

  let industryTitle: string | null = null
  let serviceTitle: string | null = null

  if (industryUUId) {
    try {
      const { data } = await fetchIndustryByUUId(industryUUId)

      industryTitle = data?.stories?.[0]?.content?.title || null
    } catch (error) {
      console.error('Failed to fetch industry data:', error)
    }
  }
  if (serviceUUId) {
    try {
      const { data } = await fetchServiceByUUId(serviceUUId)

      serviceTitle = data?.stories?.[0]?.content?.title || null
    } catch (error) {
      console.error('Failed to fetch industry data:', error)
    }
  }

  const summaryListData = [
    {
      title: t('industry'),
      value: industryTitle
    },
    {
      title: t('service'),
      value: serviceTitle
    },
    {
      title: t('team'),
      value: team
    },
    {
      title: t('projectState'),
      value: projectState
    },
    { title: t('country'), value: country, image: flagImage }
  ]
  return (
    <div className="flex flex-col gap-y-md px-x-sm lg:px-lg pt-lg-mobile xl:pt-lg xl:flex-row relative">
      <div className="flex flex-col sm:flex-row gap-md justify-between xl:flex-col xl:mb-lg">
        <div className="basis-1/2 max-w-[480px]">
          {breadcrumbs}
          <Space size="xSmall" />
          <Typography variant="h2" weight="semibold">
            {title}
          </Typography>
          <Space size="small" />
          <Typography variant="body-large" weight="medium">
            {description}
          </Typography>
        </div>
        <div className="max-w-[350px] flex flex-col justify-start gap-y-md">
          {summaryListData.map((item) =>
            item.value ? (
              <ProjectSummaryItem key={item.title} title={item.title} value={item.value} image={item.image} />
            ) : null
          )}
        </div>
      </div>
      <Image
        src={previewImage?.filename ?? ''}
        alt={previewImage?.alt ?? ''}
        width={878}
        height={526}
        className="blok xl:absolute bottom-0 right-0 aspect-[1.6/1] object-cover self-center"
      />
    </div>
  )
}

export default CaseStudyHero
