import { FC } from 'react'
import { Carousel, CarouselContent, CarouselItem, cn } from '@dbbs/tailwind-components'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import { Blok, CarouselStoryblok } from '../types'
import { resolveBreakpoints } from '../../utils/resolveBreakpoints'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const itemsPerViewBasisMapping = {
  1: 'basis-full',
  2: 'basis-1/2',
  3: 'basis-1/3',
  4: 'basis-1/4',
  5: 'basis-1/5',
  6: 'basis-1/6',
  7: 'basis-1/7',
  8: 'basis-1/8',
  9: 'basis-1/9',
  10: 'basis-1/10',
  11: 'basis-1/11',
  12: 'basis-1/12'
}

const StoryblokCarousel: FC<Blok<CarouselStoryblok>> = ({ blok }) => {
  const {
    content,
    loop,
    dragFree,
    autoScroll,
    stopOnInteraction,
    stopOnMouseEnter,
    playOnInit,
    speed,
    perView,
    perViewMd,
    perViewLg,
    align,
    dragScrolling,
    fadeEffect,
    navigationsSlot,
    autoPlay,
    autoPlayOnInit,
    delay,
    enableBorderBrightening,
    borderBrighteningColor,
    containScroll
  } = blok

  return (
    <Carousel
      {...storyblokEditable(blok)}
      opts={{
        loop,
        dragFree,
        ...(align && { align }),
        watchDrag: dragScrolling,
        containScroll: containScroll ? 'trimSnaps' : false
      }}
      autoScroll={autoScroll}
      autoplay={autoPlay}
      autoplayOptions={{ delay: Number(delay) || 3000, playOnInit: autoPlayOnInit }}
      fade={fadeEffect}
      autoScrollOptions={{ playOnInit, stopOnInteraction, stopOnMouseEnter, speed: speed?.value || 1 }}
      className={cn({
        [`before:absolute before:content-[""] before:top-0 before:left-0 before:w-12 before:h-full before:z-10 before:pointer-events-none before:bg-gradient-to-r before:from-${borderBrighteningColor} before:via-${borderBrighteningColor}/[0.1] before:to-transparent after:absolute after:content-[""] after:top-0 after:right-0 after:w-12 after:h-full after:z-10 after:pointer-events-none after:bg-gradient-to-l after:from-${borderBrighteningColor} after:via-${borderBrighteningColor}/[0.1] after:to-transparent`]:
          enableBorderBrightening
      })}
    >
      <CarouselContent
        className={cn(
          resolveBreakpoints(blok, 'contentPaddingTop', 'pt-{value}'),
          resolveBreakpoints(blok, 'contentPaddingBottom', 'pb-{value}'),
          resolveBreakpoints(blok, 'contentPaddingLeft', 'pl-{value}'),
          resolveBreakpoints(blok, 'contentPaddingRight', 'pr-{value}')
        )}
      >
        {content?.map((contentBlok) => (
          <CarouselItem
            key={contentBlok._uid}
            className={cn(
              'select-none',
              itemsPerViewBasisMapping[(perView?.value || 1) as keyof typeof itemsPerViewBasisMapping],
              `md:${itemsPerViewBasisMapping[(perViewMd?.value || 1) as keyof typeof itemsPerViewBasisMapping]}`,
              `lg:${itemsPerViewBasisMapping[(perViewLg?.value || 1) as keyof typeof itemsPerViewBasisMapping]}`
            )}
          >
            <StoryblokServerComponent blok={contentBlok} />
          </CarouselItem>
        ))}
      </CarouselContent>
      <StoryblokBlockMapper blocks={navigationsSlot} />
    </Carousel>
  )
}

export default StoryblokCarousel
