import { cn } from '@dbbs/tailwind-components'
import { TrustedByProps } from './types'

const TrustedBy = ({ className, content }: TrustedByProps) => (
  <div className={cn('flex flex-col gap-8 [&>*]:self-end max-lg:[&>*:not(:last-child)]:self-auto', className)}>
    {content}
  </div>
)

export default TrustedBy
