import React from 'react'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import { Blok, PageStoryblok } from '../types'

const StoryblokPage: React.FC<Blok<PageStoryblok>> = ({ blok }) => (
  <main className="grow" {...storyblokEditable(blok)}>
    {blok.body?.map((nestedBlok) => (
      <StoryblokServerComponent blok={{ ...nestedBlok, parentProps: blok?.parentProps }} key={nestedBlok._uid} />
    ))}
  </main>
)

export default StoryblokPage
