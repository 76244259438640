import {
  buttonVariants,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@dbbs/tailwind-components'
import { getTranslations } from 'next-intl/server'
import Link from 'next/link'
import { NavArrowDown } from 'iconoir-react'
import { CategoryPanelProps } from './types'
import Typography from '../../atoms/Typography'

const getHref = ({
  authorSlug,
  categorySlug,
  isDefault
}: {
  authorSlug?: string
  categorySlug: string
  isDefault: boolean
}) => {
  if (authorSlug) {
    return isDefault ? `/insights/author/${authorSlug}` : `/insights/author/${authorSlug}/category/${categorySlug}`
  }
  return isDefault ? '/insights' : `/insights/category/${categorySlug}`
}

const CategoryPanel = async ({ categories, selectedCategorySlug, authorSlug }: CategoryPanelProps) => {
  const t = await getTranslations()
  const defaultCategory = { name: t('common.allArticles'), slug: 'allArticles' }
  const categoriesWithDefault = [defaultCategory, ...categories]
  const selectedCategoryName = categories.find(
    ({ slug }) => slug === selectedCategorySlug || (slug === defaultCategory.slug && !selectedCategorySlug)
  ) || { name: defaultCategory.name }

  return (
    <>
      <div className="hidden sm:flex gap-3 flex-wrap justify-center">
        {categoriesWithDefault.map(({ name, slug }: { name: string; slug: string }, index: number) => {
          const isDefault = name === defaultCategory.name
          const href = getHref({ categorySlug: slug, isDefault, authorSlug })
          const isSelected = slug === selectedCategorySlug || (slug === defaultCategory.slug && !selectedCategorySlug)

          return (
            <Link
              key={`index-${index}-${slug}`}
              scroll={false}
              href={href}
              className={buttonVariants({
                variant: 'secondary',
                size: 'link',
                className: cn('px-4 py-3', {
                  'bg-primary text-white': isSelected
                })
              })}
            >
              {name}
            </Link>
          )
        })}
      </div>
      <div className="w-full block sm:hidden">
        <DropdownMenu>
          <DropdownMenuTrigger className="bg-background-grey w-full px-5 py-3 rounded-sm">
            <div className="flex justify-between">
              <Typography variant="body-large">{selectedCategoryName.name}</Typography> <NavArrowDown />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-background-grey  min-w-[var(--radix-popper-anchor-width)]">
            <DropdownMenuGroup>
              {categoriesWithDefault.map(({ name, slug }: { name: string; slug: string }, index: number) => {
                const isDefault = name === defaultCategory.name
                const href = getHref({ categorySlug: slug, isDefault, authorSlug })
                const isSelected =
                  slug === selectedCategorySlug || (slug === defaultCategory.slug && !selectedCategorySlug)

                return (
                  <DropdownMenuItem asChild key={`index-${index}-${slug}`}>
                    <Link
                      scroll={false}
                      href={href}
                      className={cn('px-4 py-3', {
                        'text-primary': isSelected
                      })}
                    >
                      <Typography variant="body-large">{name}</Typography>
                    </Link>
                  </DropdownMenuItem>
                )
              })}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  )
}

export default CategoryPanel
