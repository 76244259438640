import React, { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, ConsentSettingsTriggerStoryblok } from '../types'
import ConsentSettingsTrigger from '../../components/atoms/ConsentSettingsTrigger'

const StoryblokConsentSettingsTrigger: FC<Blok<ConsentSettingsTriggerStoryblok>> = ({ blok: { label, ...blok } }) => (
  <ConsentSettingsTrigger label={label} {...storyblokEditable(blok)} />
)

export default StoryblokConsentSettingsTrigger
