import { CarouselNext, CarouselPrevious, cn } from '@dbbs/tailwind-components'
import { ArrowLeft, ArrowRight } from 'iconoir-react'
import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, CarouselNavigationsStoryblok } from '../types'

const navigationsStylesMapping = {
  leftTop: 'top-0',
  rightTop: 'right-0 top-0',
  leftBottom: 'bottom-0',
  rightBottom: 'right-0 bottom-0',
  topBetween: 'justify-between w-full top-0',
  centerBetween: 'justify-between top-0 items-center w-full h-full',
  bottomBetween: 'justify-between w-full bottom-0'
}

const CarouselNavigations: FC<Blok<CarouselNavigationsStoryblok>> = ({ blok: { navigationsAlignment, ...blok } }) => (
  <div
    {...storyblokEditable(blok)}
    className={cn(
      'flex absolute gap-md',
      navigationsStylesMapping[navigationsAlignment as keyof typeof navigationsStylesMapping]
    )}
  >
    <CarouselPrevious className="static transform-none pointer-events-auto" variant="secondary" size="default">
      <ArrowLeft className="h-4 w-4" />
    </CarouselPrevious>
    <CarouselNext className="static transform-none pointer-events-auto" variant="secondary" size="default">
      <ArrowRight className="h-4 w-4" />
    </CarouselNext>
  </div>
)

export default CarouselNavigations
