import { storyblokEditable } from '@storyblok/react/rsc'
import { FC } from 'react'
import { Blok, CaseStudyRelatedCarouselStoryblok } from '../types/index'
import CaseStudyRelatedCarousel from '../../components/organisms/CaseStudyRelatedCarousel'

const StoryblokCaseStudyRelatedCarousel: FC<Blok<CaseStudyRelatedCarouselStoryblok>> = ({
  blok: {
    caseStudies,
    parentProps: { currentCaseStudyId },
    ...blok
  }
}) => (
  <CaseStudyRelatedCarousel
    caseStudyIds={caseStudies}
    currentCaseStudyId={currentCaseStudyId}
    {...storyblokEditable(blok)}
  />
)

export default StoryblokCaseStudyRelatedCarousel
