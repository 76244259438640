import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, PreBuiltSolutionsHeroStoryblok } from '../types'
import PreBuiltSolutionsHero from '../../components/organisms/PreBuiltSolutionsHero'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokPreBuiltSolutionsHero: FC<Blok<PreBuiltSolutionsHeroStoryblok>> = ({
  blok: { clutchContent, headerContent, trustedByLogosContent, ...props }
}) => (
  <PreBuiltSolutionsHero
    clutchContent={<StoryblokBlockMapper blocks={clutchContent} />}
    headerContent={<StoryblokBlockMapper blocks={headerContent} />}
    trustedByLogosContent={<StoryblokBlockMapper blocks={trustedByLogosContent} />}
    {...storyblokEditable(props)}
  />
)

export default StoryblokPreBuiltSolutionsHero
