import { Building, Cart, Cash, DeliveryTruck, Healthcare, Plus, QuestionMark, UserSquare } from 'iconoir-react'
import { forwardRef, memo } from 'react'
import { IconComponentType, IconProps } from './types'

const iconMap: Record<string, IconComponentType> = {
  Plus,
  Healthcare,
  Cart,
  DeliveryTruck,
  UserSquare,
  Building,
  Cash,
  QuestionMark
}

const Icon = memo(
  forwardRef<SVGSVGElement, IconProps>(({ name, ...rest }, ref) => {
    const IconComponent = iconMap[name] || iconMap.QuestionMark
    return <IconComponent ref={ref} {...rest} />
  })
)

export default Icon
