import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, CaseStudyHeroStoryblok } from '../types'
import CaseStudyHero from '../../components/organisms/CaseStudyHero'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokCaseStudyHero: FC<Blok<CaseStudyHeroStoryblok>> = ({
  blok: { title, description, flagImage, country, projectState, team, previewImage, parentProps, breadcrumbs, ...blok }
}) => (
  <CaseStudyHero
    title={title}
    description={description}
    flagImage={flagImage}
    country={country}
    projectState={projectState}
    team={team}
    industryUUId={parentProps?.industryIds[0]}
    serviceUUId={parentProps?.serviceIds[0]}
    previewImage={previewImage}
    breadcrumbs={<StoryblokBlockMapper blocks={breadcrumbs} />}
    {...storyblokEditable(blok)}
  />
)

export default StoryblokCaseStudyHero
