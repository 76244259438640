import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import Image from 'next/image'
import { Blok, ProcessStepsCarouselStoryblok } from '../types'
import ProcessStepsCarousel from '../../components/organisms/ProcessStepsCarousel'

import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokProcessStepsCarousel: FC<Blok<ProcessStepsCarouselStoryblok>> = ({ blok }) => {
  const mappedSteps = blok.steps.map(({ title, description, image }, index) => ({
    title,
    description: <StoryblokBlockMapper blocks={description} />,
    image: (
      <Image
        src={image.filename ?? ''}
        alt={image.alt ?? ''}
        width={350}
        height={350}
        className="object-cover aspect-square"
      />
    ),
    stepNumber: index + 1,
    isImageFirst: index % 2 !== 0
  }))

  return <ProcessStepsCarousel {...storyblokEditable(blok)} steps={mappedSteps} />
}

export default StoryblokProcessStepsCarousel
