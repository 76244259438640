import { FC } from 'react'
import { Blok, GlobalTemplateStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokGlobalTemplate: FC<Blok<GlobalTemplateStoryblok>> = ({ blok }) => (
  <>
    <StoryblokBlockMapper blocks={blok.header} />
    <footer>
      <StoryblokBlockMapper blocks={blok.footer} />
    </footer>
  </>
)

export default StoryblokGlobalTemplate
