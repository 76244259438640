import { cn } from '@dbbs/tailwind-components'

import { ProcessStepCardProps } from './types'
import Space from '../../atoms/Space'
import Typography from '../../atoms/Typography'

const ProcessStepCard = ({ title, description, image, isImageFirst, stepNumber }: ProcessStepCardProps) => (
  <div className="flex flex-col gap-y-sm">
    <div className={cn({ 'order-1': isImageFirst })}>{image}</div>
    <div>
      {stepNumber && (
        <>
          <Typography variant="link" weight="semibold" className="text-gradient-text">
            {`Step ${stepNumber}`}
          </Typography>
          <Space size="xxSmall" />
        </>
      )}
      <Typography variant="h5" weight="semibold">
        {title}
      </Typography>
      <Space size="xxSmall" />
      {description}
    </div>
  </div>
)

export default ProcessStepCard
