import { createElement, FC, Fragment, ReactNode } from 'react'
import {
  BlockTypes,
  MarkTypes,
  richTextResolver,
  StoryblokRichTextNode,
  StoryblokRichTextOptions
} from '@storyblok/richtext'
import { SbBlokData, storyblokEditable, StoryblokServerComponent } from '@storyblok/react/rsc'
import { cn } from '@dbbs/tailwind-components'
import Image from 'next/image'
import { Blok, RichTextStoryblok } from '../types'
import Typography from '../../components/atoms/Typography'
import { slugify } from '../../utils/slugify'
import { getDimensionsFromStoryblokImageUrl } from '../../utils/getDimensionsFromStoryblokImageUrl'
import { removeRichTextStyleMarks } from '../../utils/removeRichTextStyleMarks'

const StoryblokRichText: FC<Blok<RichTextStoryblok>> = ({ blok: { text, isInsightPost, ...props } }) => {
  const options: StoryblokRichTextOptions<ReactNode> = {
    renderFn: createElement,
    textFn: (contentText) => contentText,
    optimizeImages: true,
    keyedResolvers: true,
    resolvers: {
      [MarkTypes.LINK]: (node) => (
        <a href={node.attrs?.href} target={node.attrs?.target} className="text-primary hover:underline">
          {node.text}
        </a>
      ),
      [BlockTypes.HEADING]: (node) => {
        switch (node.attrs?.level) {
          case 1:
            return (
              <Typography variant="h1" renderAs="h1">
                {node.children}
              </Typography>
            )
          case 2: {
            const title = Array.isArray(node.content) && node.content.length > 0 ? node.content[0].text || '' : ''
            const id = isInsightPost ? slugify(title) : undefined

            return (
              <Typography
                variant={isInsightPost ? 'h3' : 'h2'}
                renderAs="h2"
                id={id}
                className={cn({
                  'scroll-mt-20': isInsightPost
                })}
              >
                {node.children}
              </Typography>
            )
          }
          case 3:
            return (
              <Typography variant={isInsightPost ? 'h4' : 'h3'} renderAs="h3">
                {node.children}
              </Typography>
            )
          case 4:
            return (
              <Typography variant={isInsightPost ? 'h5' : 'h4'} renderAs="h4">
                {node.children}
              </Typography>
            )
          case 5:
            return (
              <Typography variant={isInsightPost ? 'body' : 'h5'} renderAs="h5">
                {node.children}
              </Typography>
            )
          default:
            return (
              <Typography
                variant={isInsightPost ? 'body-large' : 'body'}
                className={cn({
                  'font-normal': !isInsightPost
                })}
              >
                {node.children}
              </Typography>
            )
        }
      },
      [BlockTypes.PARAGRAPH]: (node) => (
        <Typography
          variant={isInsightPost ? 'body-large' : 'body'}
          className={cn({
            'font-normal': !isInsightPost
          })}
        >
          {node.children}
        </Typography>
      ),
      [BlockTypes.OL_LIST]: (node) => (
        <ol className="list-decimal my-5 ps-[1.626rem] [&>li]:ps-[0.375rem] [&>li]:my-2">{node.children}</ol>
      ),
      [BlockTypes.UL_LIST]: (node) => (
        <ul className="list-disc my-5 ps-[1.626rem] [&>li]:ps-[0.375rem] [&>li]:my-2">{node.children}</ul>
      ),
      [BlockTypes.COMPONENT]: (node) => {
        const blocks = node.attrs?.body as SbBlokData[]
        return (
          <Fragment>
            {blocks.map((block) => (
              <StoryblokServerComponent key={block._uid} blok={block} />
            ))}
          </Fragment>
        )
      },
      [BlockTypes.QUOTE]: (node) => (
        <blockquote className="border-l-4 my-4 border-primary pl-4 italic">{node.children}</blockquote>
      ),
      [BlockTypes.CODE_BLOCK]: (node) => (
        <pre className="bg-background-grey border border-secondary-grey p-4 overflow-x-auto rounded-sm">
          <code>{node.children}</code>
        </pre>
      ),
      [MarkTypes.CODE]: (node) => (
        <code className="bg-background-grey p-2 border border-secondary-grey overflow-x-auto rounded-sm text-alert">
          {node.text}
        </code>
      ),
      [BlockTypes.IMAGE]: (node) => {
        const dimensionsFromStoryblokImageUrl = getDimensionsFromStoryblokImageUrl(node.attrs?.src)
        return (
          <Image
            src={node.attrs?.src}
            alt={node.attrs?.alt}
            width={dimensionsFromStoryblokImageUrl?.width}
            height={dimensionsFromStoryblokImageUrl?.height}
          />
        )
      }
    }
  }

  const textWithoutStyles = {
    ...text,
    content: removeRichTextStyleMarks(text.content ?? [])
  }

  return (
    <div {...storyblokEditable(props)}>
      {richTextResolver<ReactNode>(options).render(textWithoutStyles as StoryblokRichTextNode<ReactNode>)}
    </div>
  )
}

export default StoryblokRichText
