import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, cn } from '@dbbs/tailwind-components'
import { Minus, Plus } from 'iconoir-react'
import Link from 'next/link'
import Typography from '../../atoms/Typography'
import Space from '../../atoms/Space'
import { CaseStudyFilterAccordionProps } from './types'

const CaseStudyFilterAccordion = ({ categoryGroups }: CaseStudyFilterAccordionProps) => {
  if (categoryGroups.length === 0) return null

  return (
    <Accordion
      type="multiple"
      defaultValue={[categoryGroups[0].title]}
      className="w-full flex flex-col gap-6 sticky top-xx-lg"
    >
      {categoryGroups.map((group) => (
        <AccordionItem key={`ac-group-${group.title}`} value={group.title} className="last:border-none">
          <AccordionTrigger className="p-0 hover:text-primary" openIcon={Plus} closeIcon={Minus}>
            <Typography variant="h5" weight="semibold">
              {group.title}
            </Typography>
          </AccordionTrigger>
          <Space size="small" />
          <AccordionContent className="flex flex-col gap-md pb-6">
            <Link scroll={false} href={{ ...group.linkGroup }}>
              <Typography variant="link" weight="semibold" className={cn({ 'text-primary': !group.selectedCategory })}>
                {`All ${group.title}`}
              </Typography>
            </Link>
            {group.categories.map((category) => (
              <Link key={category.uuid} scroll={false} href={{ ...category.link }}>
                <Typography
                  variant="link"
                  weight="semibold"
                  className={cn('hover:text-primary hover:underline', {
                    'text-primary': group.selectedCategory?.slug === category.slug
                  })}
                >
                  {category.content.title}
                </Typography>
              </Link>
            ))}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default CaseStudyFilterAccordion
