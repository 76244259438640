import Image from 'next/image'
import { useTranslations } from 'next-intl'
import Typography from '../../atoms/Typography'
import { PreBuiltSolutionsHeroProps } from './types'

const PreBuiltSolutionsHero = ({ trustedByLogosContent, headerContent, clutchContent }: PreBuiltSolutionsHeroProps) => {
  const t = useTranslations()
  return (
    <div className="relative bg-background-grey px-x-sm py-md-deluxe pb-0 lg:p-lg rounded-md lg:rounded-lg flex flex-col lg:flex-row justify-between gap-md-deluxe lg:gap-lg lg:mb-60 xl:mb-72">
      <div className="flex flex-col gap-28 justify-between max-sm:[&_h1]:text-[40px]">
        {headerContent}
        <div className="hidden lg:flex flex-col gap-md-deluxe">{clutchContent}</div>
      </div>
      <div className="flex flex-col gap-md items-end shrink-0">
        <div className="flex justify-between max-lg:w-full items-center">
          <Typography variant="h5" weight="semibold">
            {t('hero.trustedBy')}
          </Typography>
          <Typography variant="h5" weight="semibold" className="block lg:hidden">
            {`& 30 ${t('hero.more')}`}
          </Typography>
        </div>
        <div className="grid grid-cols-[repeat(auto-fit,minmax(144px,1fr))] max-lg:w-full gap-3 md:gap-md">
          {trustedByLogosContent}
        </div>

        <Typography variant="h5" weight="semibold" className="hidden lg:block">
          & 30 more
        </Typography>
      </div>
      <div className="flex lg:hidden md:justify-end items-center gap-md md:gap-md-deluxe">{clutchContent}</div>
      <div className="max-lg:before:absolute max-lg:before:rounded-b-md max-lg:relative max-lg:before:w-full max-lg:before:h-1/2 max-lg:before:bg-background-grey max-lg:bg-white max-lg:-m-x-sm max-lg:self-center lg:absolute lg:max-w-[704px] lg:-bottom-1/4 xl:top-[45%] lg:left-1/2 lg:-translate-x-1/2">
        <Image
          src="/img/preBuiltSolutionHeroImage.webp"
          alt="Pre built solutions image"
          width={1100}
          height={800}
          quality={60}
          className="max-lg:relative max-lg:z-10"
        />
      </div>
    </div>
  )
}

export default PreBuiltSolutionsHero
