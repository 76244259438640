import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import { Blok, RelationCarouselStoryblok } from '../types'

const StoryblokRelationCarousel: FC<Blok<RelationCarouselStoryblok>> = ({ blok }) => (
  <StoryblokBlockMapper {...storyblokEditable(blok)} blocks={blok.carousel} />
)

export default StoryblokRelationCarousel
