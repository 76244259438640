import Image from 'next/image'
import { HeroProps } from './types'
import TrustedBy from './trustedBy'

const Hero = ({
  title,
  backgroundImageUrl,
  backgroundImageVideoUrl,
  description,
  badge,
  buttons,
  trustedBy
}: HeroProps) => (
  <>
    <div>
      <div className="relative px-4 py-12 lg:p-16 before:absolute before:z-10 before:content-[''] before:rounded-t-2xl lg:before:rounded-t-[64px] before:rounded-b-sm before:top-0 before:left-0 before:w-full before:h-full before:opacity-50 before:bg-dark">
        <Image
          src={backgroundImageUrl}
          alt="Background image"
          priority
          fill
          sizes="100vw"
          className="absolute left-0 top-0 h-full w-full rounded-t-2xl lg:rounded-t-[64px] rounded-b-sm object-left-top object-cover z-[1]"
        />
        <div className="absolute left-0 top-0 h-full w-full z-[2]">
          <video
            autoPlay
            muted
            loop
            controls={false}
            aria-hidden="true"
            poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
            className="rounded-t-2xl lg:rounded-t-[64px] rounded-b-sm object-cover h-full w-full hidden md:block opacity-0 [&:has(source)]:opacity-100"
          >
            <source src={backgroundImageVideoUrl} type="video/mp4" media="screen and (min-width: 768px)" />
          </video>
        </div>
        <div className="relative z-10 flex flex-col lg:flex-row justify-between gap-8">
          <div className="max-md:flex flex-col max-sm:items-center gap-6">
            {badge && (
              <div className="inline-flex md:mb-7">
                <div className="px-[10px] py-2 border border-white rounded-sm text-white flex-grow-0 inline-flex">
                  {badge}
                </div>
              </div>
            )}
            <div className="uppercase md:max-w-[470px]">{title}</div>
            {description && <div className="md:max-w-md md:mt-16 lg:mt-32 xl:mt-16">{description}</div>}
          </div>
          <div className="flex flex-col justify-between gap-16">
            <TrustedBy content={trustedBy} className="max-lg:hidden" />
            <div className="flex flex-col md:flex-row gap-7 items-center lg:justify-end [&_*]:max-md:w-full">
              {buttons}
            </div>
          </div>
        </div>
      </div>
      <div className="py-12 px-4 bg-dark mt-4 rounded-sm lg:hidden">
        <TrustedBy content={trustedBy} />
      </div>
    </div>
  </>
)

export default Hero
