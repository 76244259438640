/**
 * Shuffles an array randomly using the Fisher-Yates algorithm.
 *
 * This function creates a new array, shuffles its elements in place,
 * and returns the shuffled array. The original array remains unmodified.
 *
 * @template T - The type of the elements in the array.
 * @param {T[]} array - The array to shuffle.
 * @returns {T[]} A new array with the elements shuffled randomly.
 *
 * @example
 * const numbers = [1, 2, 3, 4, 5];
 * const shuffled = shuffleArray(numbers);
 * console.log(shuffled); // Example output: [3, 1, 5, 4, 2]
 */
export const shuffleArray = <T>(array: T[]): T[] => {
  const shuffledArray = [...array]
  for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]] // Swap
  }
  return shuffledArray
}
